import "./navbar.css";
import "./icons.css"
import { useEffect, useContext, useRef } from "react";
import { IdiomaContext } from "../../context/IdiomaContext";
// import {style1, style2} from "./navbarStyle"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const NavBar = () => {
    const thisLocation = useLocation();
    // const [update, setUpdate] = useState(true)
    const {setSpanish, setInglish, isInglish} = useContext(IdiomaContext);
    const navigateTo = useNavigate();
    const contMenu = useRef();
    const menu = useRef();
    const botonMenu = useRef();
    const botonSubMenu = useRef();
           
    // const scrollWhitOffSet = (el) => {                                      //Código para que al ir a la sección de "Experiencia Little Creek" el titulo de la subsección elegida no quede oculto abajo del navbar
    //     const yPos = el.getBoundingClientRect().top + window.pageYOffset;
    //     window.scrollTo({top: yPos - 150});                                 //Al ir a la subseccion la página baja 100px mas
    // }

    const restaurarSeccion = () => {
        if(document.querySelector(".contSeccion")) {
            document.querySelector(".contSeccion").classList.remove("seccionBaja"); /////////////////////////////////////////////////////////////////
            document.querySelector(".contSeccion").classList.remove("seccionSube");
        }
    }

    const restaurarMenu = () => {
        document.querySelector(".menu").classList.remove("menuSeAgranda");
        document.querySelector(".menu").classList.remove("menuSeAchica");
        document.querySelector(".menu").classList.remove("menuEnCero");
    }

    const subMenuAnimationClasses = ["subMenuSube", "subMenuBaja", "subMenuOpacityOff", "subMenuOpacityOn"];

    useEffect(() => {
        let pass = true;

        // const elements = document.getElementsByClassName("OoS");            //Para que al cargar la página se produzca el efecto de OoS (Opacity on Scroll) sin hacer scroll
        // for (const element of elements) {                                       
        //     if (element.getBoundingClientRect().top < window.innerHeight) {     
        //         element.style.opacity = 1;
        //     };
        // };
        // window.addEventListener("scroll", () => {
        //     for (const element of elements) {
        //         if (element.getBoundingClientRect().top < window.innerHeight) { //Efecto opacity on scroll
        //             element.style.opacity = 1;
        //         };
        //     };
        // });
                   
        // const OverBreakPoint = (windowWidth) => {                     //Definimos el ancho de pantalla en REM para cambio de vista del NavBar. Como el Rem cambia de valor segun el tamaño del window el breakPoint es adaptable y va cambiando y por eso el navBar no se recorta nunca por la pantalla
        //     const widthBreak = 92.5 * (0.01 * window.innerHeight + 10);       //BreakPoint en 100rem
        //     if (windowWidth >= widthBreak) {
        //         return true
        //     } else {
        //         return false
        //     }
        // }        

        const restaurarSubmenus = () => {                                 //Removemos los Submenus que se insertaron en contMenu. (Si las hay)

            if (window.innerWidth <= 1100) {  
    
                const contIconSubMenu = document.querySelector(".contIconSubMenu");
                if (contIconSubMenu.lastChild.getAttribute("name") === "subMenu") {
                    contIconSubMenu.lastChild.style.display = "none";
                    contIconSubMenu.lastChild.setAttribute("position", "up");
                    contIconSubMenu.lastChild.classList.remove(...subMenuAnimationClasses);
                    const contsSubMenu = document.getElementsByClassName("contSubMenu");
                    for (const cont of contsSubMenu) {
                        if(cont.childNodes.length === 0) {
                            cont.appendChild(contIconSubMenu.lastChild);
                        }
                    }
                }
    
            } else { 
                const lastChild = contMenu.current.lastChild;
                if (lastChild.getAttribute("name") === "subMenu") {
                    const subMenu = lastChild;     
                    subMenu.style.display = "none"; 
                    subMenu.setAttribute("position", "up");
                    subMenu.classList.remove(...subMenuAnimationClasses);
                    const contsSubMenu = document.getElementsByClassName("contSubMenu");
                    for (const cont of contsSubMenu) {
                        if(cont.childNodes.length === 0) {
                            cont.appendChild(subMenu);
                        }
                    }
                }
            }
        }
        
        const cargarSubMenus = () => {                                                                   //Para que al recargar o entrar directamente a una seccion aparezca el submenu (Si la seccion tiene submenu)
    
            const actualRoute = window.location.href;
            const opcionesMenu = document.getElementsByClassName("opcionMenu");
            for (const opcion of opcionesMenu) {
                if((actualRoute.includes(opcion.getAttribute("route")) && (opcion.getAttribute("isoption") !== "true"))) {
                    const subMenu = opcion.nextSibling.firstChild;
                    if (subMenu){ 
                        if (window.innerWidth > 1100)  {
                            subMenu.style.display = "flex"  
                            contMenu.current.appendChild(subMenu);
                            subMenu.classList.remove(...subMenuAnimationClasses);
                            subMenu.classList.add("subMenuSube")
                        } else {
                            subMenu.style.display = "block";
                            document.querySelector(".contIconSubMenu").style.display = "block";
                            contMenu.current.lastChild.appendChild(subMenu);
                        }
                    }
                    if (window.innerWidth > 1100)  {   
                        restaurarSeccion();
                        if (document.querySelector(".contSeccion")) document.querySelector(".contSeccion").classList.add("seccionSube");    ////////////////////////////////////////////////
                    } else {
                        restaurarMenu();
                        menu.current.classList.add("menuSeAchica");
                        document.querySelector(".contIconSubMenu").classList.remove(...subMenuAnimationClasses);
                        document.querySelector(".contIconSubMenu").classList.add("subMenuOpacityOn");
                        restaurarSeccion();
                        if (document.querySelector(".contSeccion")) document.querySelector(".contSeccion").classList.add("seccionSube");            /////////////////////////////////////////
                    }  
                } else if ((actualRoute.includes(opcion.getAttribute("route")) && (opcion.getAttribute("isoption") === "true"))) {
                    document.querySelector(".contIconSubMenu").style.display = "none";
                }
            }
            
            if (document.querySelector(".contSeccion")) {
                document.querySelector(".nombreSeccion").innerText = document.querySelector(".contSeccion").getAttribute("secciontitle");        //Carga el nombre de la subSeccion en el submenu del navbar (menu minimizado)
                if (window.innerWidth <= 1100 && document.querySelector(".contIconSubMenu").lastChild.getAttribute("name") === "subMenu") {         //Carga colores en barra de submenu
                    const subMenu = document.querySelector(".contIconSubMenu").lastChild;
                    const contIconSM = document.querySelector(".contIconSM");
                    const color = subMenu.getAttribute("color");
                    contIconSM.style.backgroundColor = color;
                    subMenu.style.backgroundColor = color;
                } else if (window.innerWidth > 1100 && contMenu.current.lastChild.getAttribute("name") === "subMenu") {
                    const subMenu = contMenu.current.lastChild;
                    const color = subMenu.getAttribute("color"); 
                    subMenu.style.backgroundColor = color;
                }
            }
        }      
      
        restaurarSubmenus();            //Para que al recargar o entrar directamente a una seccion aparezca el submenu (Si la seccion tiene submenu)
        cargarSubMenus();
         
        const cargarMenu = (opc) => {
            const opcionesMenu = document.getElementsByClassName(opc);        //Codigo para setear la opcion del MENU que se pone en negrita segun ruta     
            for (const opcion of opcionesMenu) {                        //Este codigo pone en negrita la opcion del menu que corresponda al recargar la pagina
                opcion.style.fontWeight = 400;                          //O cambiar de ruta (primero pone todo en font weight 300) despues la opcion que corresponda segun la ruta en 700
            }                                                             
            for (const opcion of opcionesMenu) {
                const route = opcion.getAttribute("route");
                if (window.location.href.toLowerCase().includes(route)) opcion.style.fontWeight = 700;
            }
        }
        cargarMenu("opcionMenu");                                       //Verificamos ruta actual con opciones con submenu
    
        setTimeout(() => {                                              //Ponemos en negrita las opciones del SUBMENU que correspondan. (Sin el settimeout no funciona)
            const subsecciontitle = document.querySelector(".contSeccion").getAttribute("subsecciontitle");
            if (window.innerWidth > 1100) {
                if (contMenu.current.lastChild.getAttribute("name") === "subMenu") {
                    const subMenu = contMenu.current.lastChild;
                    const opciones = subMenu.childNodes;
                    for (const opc of opciones) {
                        opc.style.fontWeight = 400;
                    }
                    for (const opc of opciones) {
                        if (opc.innerText.includes(subsecciontitle)) opc.style.fontWeight = 700;
                    }
                }
            }
            if (window.innerWidth <= 1100) {
                if (document.querySelector(".contIconSubMenu").lastChild.getAttribute("name") === "subMenu") {
                    const subMenu = document.querySelector(".contIconSubMenu").lastChild;
                    const opciones = subMenu.childNodes;
                    for (const opc of opciones) {
                        opc.style.fontWeight = 300;
                    }
                    for (const opc of opciones) {
                        if (opc.innerText.includes(subsecciontitle)) opc.style.fontWeight = 700;
                    }
                }
            }
        }, 100);          
                       
    // if (!document.getElementsByClassName("styleNavBarExtend")[0]) {                         //Insertamos estilos css dinamicos
    //     const css1 = document.createElement("style");   
    //     css1.className = "styleNavBarExtend"
    //     css1.innerText = style1;
    //     document.head.appendChild(css1)
    // }    
    // if (!document.getElementsByClassName("styleNavBarCollapse")[0] && !OverBreakPoint(window.innerWidth)) {
    //     const css2 = document.createElement("style");
    //     css2.className = "styleNavBarCollapse"
    //     css2.innerText = style2;
    //     document.head.appendChild(css2)
    // }    
    // if (document.getElementsByClassName("styleNavBarCollapse")[0] && OverBreakPoint(window.innerWidth)) {
    //     const cssToRemove = document.getElementsByClassName("styleNavBarCollapse")[0];
    //     cssToRemove.remove();
    // }    
    
    // let widthOrigin = window.innerWidth;
    // let pass = true;            // Flag para impedir que antes reiterados cambios de tamaño de pantalla rapidos se entre denuevo al codigo. Recien se permite la entrada al finalizar el codigo interior del listener
    // window.addEventListener("resize", () => {       
    //     if (pass) {
    //         pass = false;
    //         if (OverBreakPoint(window.innerWidth) && !OverBreakPoint(widthOrigin)) {   //Si se cambia de "zona" de menu minimizado o zona menu extendido entramos al if
               

    //             setUpdate(!update);     //Forzamos un render para que se carguen los estilos dinamicos
    //         } else if (!OverBreakPoint(window.innerWidth) && OverBreakPoint(widthOrigin)) {   //Si se cambia de "zona" de menu extendido o zona menu minimizado entramos al else if
                
                
    //             setUpdate(!update);       //Forzamos un render para que se carguen los estilos dinamicos
    //         } else {
    //             pass = true;            //Una vez que hacemos todo lo que hay que hacer habilitamos devuelta la entrada al listener (pass)
    //         }
    //     }     
    // })
                         
        const getSubMenu = (target) => {                         // Esta funcion devuelve el submenu si se hace click en opcionMenu u opcionSubMneu
            if (target.getAttribute("name") === "opcionMenu") {
                const subMenu = target.nextSibling.firstChild;
                if (subMenu) {                                  //El menu tiene el submenu como hijo y este NO esta insertada en contMmenu
                    return subMenu;
                } else {                                        //El submenu esta insertado en contMmenu
                    if (window.innerWidth > 1100) {
                        return contMenu.current.lastChild;
                    } else {  
                        return contMenu.current.lastChild.lastChild;
                    } 
                }
            } else if (target.getAttribute("name") === "opcionSubMenu") {
                const subMenu = target.parentNode;
                return subMenu;
            } else if (target.getAttribute("isoption") === "true") {
                if (window.innerWidth > 1100) {
                    if (contMenu.current.lastChild.getAttribute("name") === "subMenu") {
                        return contMenu.current.lastChild
                    } else {
                        return false;
                    }
                } else {
                    const contIconSubMenu = document.querySelector(".contIconSubMenu")
                    if (contIconSubMenu.lastChild.getAttribute("name") === "subMenu") {
                        return contIconSubMenu.lastChild;
                    }
                    else {
                        return false
                    }
                }   
            }
        }    
                
        const opcionesSubMenu = document.getElementsByClassName("opcionSubMenu");
        for (const opcion of opcionesSubMenu) {
            opcion.addEventListener("click", (e) => {
                toggleSubMenu(e);
            })
        }
        const opcionesMenu = document.getElementsByClassName("opcionMenu");
        for (const opcion of opcionesMenu) {
                opcion.addEventListener("click", (e) => {
                toggleSubMenu(e);
            })
        }
        
        const toggleSubMenu = (e) => {      // Toggle submenu en zona de menu extendido
            
            if (pass && window.innerWidth > 1100) {         
                
                pass = false;   
                                        
                const subMenu = getSubMenu(e.target);            
                if (contMenu.current.lastChild.getAttribute("name") === "subMenu") {        //Si ya hay un submenu abierto

                    const subMenuInsertado = contMenu.current.lastChild;                    //Bajamos el menu abierto

                    subMenuInsertado.classList.remove("subMenuBaja");
                    subMenuInsertado.classList.remove("subMenuSube");
                    subMenuInsertado.classList.add("subMenuBaja");
                    restaurarSeccion();
                    document.querySelector(".contSeccion").classList.add("seccionBaja");
                    setTimeout(() => {             
                            
                        if (e.target.getAttribute("isoption") !== "true") {

                            if (subMenu.getAttribute("position") === "up") restaurarSubmenus();         //Restauramos submenus solos si estan insertados en contMenu
                            subMenu.style.display = "flex";
                            contMenu.current.appendChild(subMenu);
                            subMenu.setAttribute("position", "down");       //El submenu esta insertado en contMenu
                            subMenu.classList.remove("subMenuSube");
                            subMenu.classList.remove("subMenuBaja");
                            subMenu.classList.add("subMenuSube");

                        } else {
                            // restaurarMenu();
                            // e.target.parentNode.classList.add("menuSeAchica");
                            restaurarSubmenus();
                        }
                                                
                        navigateTo(e.target.getAttribute("link"));  //el hook usenavigate vulve a renderizar todos los coponentes inclusive el navbar. (No regarga la págna)
                                            
                        setTimeout(() => {                          //Sin el timeout no funciona el efecto de subir seccion
                            restaurarSeccion();
                            document.querySelector(".contSeccion").classList.add("seccionSube");
                        }, 0);

                        setTimeout(() => {
                            pass = true;
                        }, 1000);

                    }, 1000);                                                               //Verificar que este valor coincida con el del CSS (--tiempo-desplazamiento-menus)
            
                } else if (contMenu.current.lastChild.getAttribute("name") !== "subMenu") {        //Si no hay un submenu abierto
                                                                                
                    // restaurarMenu();
                    // e.target.parentNode.classList.add("menuSeAgranda");

                    restaurarSeccion();
                    document.querySelector(".contSeccion").classList.add("seccionBaja");
                    setTimeout(() => {
                        if (subMenu) {
                            subMenu.style.display = "flex";
                            contMenu.current.appendChild(subMenu);
                            subMenu.setAttribute("position", "down");       //El submenu esta insertado en contMenu
                        }
                            
                        if (e.target.getAttribute("isoption") !== "true") {     //Si elegimos una opcion con submenu lo subimos
                            subMenu.classList.remove("subMenuBaja");
                            subMenu.classList.add("subMenuSube");    
                            restaurarMenu();
                            e.target.parentNode.classList.add("menuEnCero");   
                        } else {
                            // restaurarMenu();
                            // e.target.parentNode.classList.add("menuSeAchica");
                        }            
                    
                        navigateTo(e.target.getAttribute("link"));

                        setTimeout(() => {                               //Sin el timeout no funciona el efecto de subir seccion
                            restaurarSeccion();
                            document.querySelector(".contSeccion").classList.add("seccionSube");
                        }, 0);                   
                        
                        setTimeout(() => {
                            pass = true;
                        }, 1000);

                    }, 1000);                                           //Verificar que este valor coincida con el del CSS (--tiempo-desplazamiento-menus)  
                } 

                /************************************************************************* Codigo Navbar para menu minimizado ************************************************************************/

            } else if (pass && window.innerWidth <= 1100) {      
        
                pass = false;   
                                        
                const subMenu = getSubMenu(e.target);            

                if (contMenu.current.lastChild.lastChild.getAttribute("name") === "subMenu") {        //Si ya hay un submenu abierto
                                    
                    document.querySelector(".contIconSubMenu").classList.remove(...subMenuAnimationClasses);
                    document.querySelector(".contIconSubMenu").classList.add("subMenuOpacityOff");
                    
                    restaurarMenu();
                    menu.current.classList.add("menuSeAgranda")

                    restaurarSeccion();
                    document.querySelector(".contSeccion").classList.add("seccionBaja");

                    setTimeout(() => {             
                            
                        if (e.target.getAttribute("isoption") !== "true") {                             //Seleccionamos una opcion CON submenu

                            if (subMenu.getAttribute("position") === "up") restaurarSubmenus();         //Restauramos submenus solos si estan insertados en contMenu
                            subMenu.style.display = "block";
                            contMenu.current.lastChild.appendChild(subMenu);
                            subMenu.setAttribute("position", "down");       //El submenu esta insertado en contMenu

                            restaurarMenu();
                            menu.current.classList.add("menuSeAchica")        

                            document.querySelector(".contIconSubMenu").classList.remove(...subMenuAnimationClasses);
                            document.querySelector(".contIconSubMenu").classList.add("subMenuOpacityOn");

                        } else {
                            restaurarMenu();
                            menu.current.classList.add("menuSeAchica");
                            restaurarSubmenus();
                        }
                                                
                        navigateTo(e.target.getAttribute("link"));  //el hook usenavigate vulve a renderizar todos los coponentes inclusive el navbar. (No regarga la págna)

                        if (document.querySelector(".contIconSubMenu").getAttribute("open") === "true") {
                            subMenu.style.maxHeight = null;         /////////////////////////////////////////////////////////// estaba en "unset"
                            subMenu.style.height = "fit-content";
                            subMenu.style.maxHeight = subMenu.scrollHeight + "px";
                        } else {
                            subMenu.style.maxHeight = null;
                        }                
                                                                                            
                        setTimeout(() => {                          //Sin el timeout no funciona el efecto de subir seccion
                            restaurarSeccion();
                            document.querySelector(".contSeccion").classList.add("seccionSube");
                        }, 0);

                        setTimeout(() => {
                            pass = true;
                        }, 1000);

                    }, 1000);                                                               //Verificar que este valor coincida con el del CSS (--tiempo-desplazamiento-menus)
            
                } else if (contMenu.current.lastChild.lastChild.getAttribute("name") !== "subMenu") {        //Si no hay un submenu abierto
                                                                                
                    restaurarMenu();
                    menu.current.classList.add("menuSeAgranda");

                    document.querySelector(".contIconSubMenu").classList.remove(...subMenuAnimationClasses);
                    document.querySelector(".contIconSubMenu").classList.add("subMenuOpacityOff");

                    restaurarSeccion();
                    document.querySelector(".contSeccion").classList.add("seccionBaja");
                    setTimeout(() => {
                        if (subMenu) {
                            subMenu.style.display = "block";
                            document.querySelector(".contIconSubMenu").style.display = "block";         
                            contMenu.current.lastChild.appendChild(subMenu);
                            subMenu.setAttribute("position", "down");       //El submenu esta insertado en contMenu
                        }
                            
                        if (e.target.getAttribute("isoption") !== "true") {     //Si elegimos una opcion con submenu lo subimos
                            restaurarMenu();
                            menu.current.classList.add("menuSeAchica")        

                            document.querySelector(".contIconSubMenu").classList.remove(...subMenuAnimationClasses);
                            document.querySelector(".contIconSubMenu").classList.add("subMenuOpacityOn");
                                
                        } else {
                            restaurarMenu();
                            e.target.parentNode.classList.add("menuSeAchica");
                        }            
                    
                        navigateTo(e.target.getAttribute("link"));

                        setTimeout(() => {                               //Sin el timeout no funciona el efecto de subir seccion
                            restaurarSeccion();
                            document.querySelector(".contSeccion").classList.add("seccionSube");
                        }, 0);                   
                        
                        setTimeout(() => {
                            pass = true;
                        }, 1000);
                            
                    }, 1000);                                           //Verificar que este valor coincida con el del CSS (--tiempo-desplazamiento-menus)  
                } 
            }    
        }
        // eslint-disable-next-line
    }, [thisLocation]);
       

    useEffect(() => {

        if (localStorage.getItem("isInglish") === "true") {                 //Leemos el localstorage para cargar el idioma
            setInglish();
        } else if (localStorage.getItem("isInglish") === "false") {
            setSpanish();
        }    

        botonMenu.current.addEventListener("click", () => {                                 //Eventos click en botones menu
            if (menu.current.style.maxHeight) {
                menu.current.style.maxHeight = null;
            } else {
                menu.current.style.maxHeight = menu.current.scrollHeight + "px";
            }
        })

        botonSubMenu.current.addEventListener("click", () => {
            if (contMenu.current.lastChild.lastChild) {
                const subMenu = contMenu.current.lastChild.lastChild;
                if (subMenu.getAttribute("name") === "subMenu") {
                    if (subMenu.style.maxHeight) {
                        subMenu.style.maxHeight = null;
                            document.querySelector(".contIconSubMenu").setAttribute("open", "false");
                    } else {
                        subMenu.style.maxHeight = subMenu.scrollHeight + "px";
                            document.querySelector(".contIconSubMenu").setAttribute("open", "true");
                    }
                }
            }
        })
        // eslint-disable-next-line
    }, []);    
    
    useEffect(() => {

        let pass = true;

        let widthOrigin = window.innerWidth;
        window.addEventListener("resize", () => {                                       //Al hacer resize y pasar a menos o mas de cierto valor de ancho de pagina la recargamos previo guardado del idioma en el storage
            if (pass) {
                pass = false;

                if (window.innerWidth > 1100 && widthOrigin <= 1100) {
                    isInglish ? localStorage.setItem("isInglish", "true") : localStorage.setItem("isInglish", "false");
                    window.location.reload();
                }
                if (window.innerWidth <= 1100 && widthOrigin > 1100) {
                    isInglish ? localStorage.setItem("isInglish", "true") : localStorage.setItem("isInglish", "false");
                    window.location.reload();
                }

                pass = true;
            }        
        })         
                       
        window.addEventListener("orientationchange", () => {                        //Al hacer un cambio de orientacion del dispositivo recargamos la pagina para reiniciar los menus
                       
            isInglish ? localStorage.setItem("isInglish", "true") : localStorage.setItem("isInglish", "false"); //Antes de recargar guardamos el idioma en el local storage
            window.location.reload();
    
        })
        
    }, [isInglish]);

    /*********************************************************************************************************************************************************/
    
    const toggleIdioma = () => {
        if (isInglish) {
            localStorage.setItem("isInglish", "false")
            setSpanish();
        } else {
            localStorage.setItem("isInglish", "true");
            setInglish();        
        }
    }

    /**********************************************************************************************************************************************************/

    const colors = {
        experiencia: getComputedStyle(document.documentElement).getPropertyValue('--color-experiencia'),            //Colores navbar
        filosofia: getComputedStyle(document.documentElement).getPropertyValue('--color-filosofia'),
        programa: getComputedStyle(document.documentElement).getPropertyValue('--color-programa'),
        admin: getComputedStyle(document.documentElement).getPropertyValue('--color-admin'),
        galeria: getComputedStyle(document.documentElement).getPropertyValue('--color-galeria'),
        team: getComputedStyle(document.documentElement).getPropertyValue('--color-team')
    }

        /**********************************************************************************************************************************************************/


    return (
        <>
            {/* {update}  Esta variable es solo para forzar renders */}
            <div className="contMenu flex column" ref={contMenu}>
                <div className="contIconMenu flex">
                    <div className="contLogoMinimized flex">
                        <Link className="contLinkLogo flex" to="/home#top"><img src="/images/logo2.png" alt="Logo" className="logo"/></Link>
                        <p className="opcionIdioma flex" onClick={toggleIdioma}>{(!isInglish && "English") || (isInglish && "Spanish")}</p>
                    </div>
                    <img className="menuIcon" ref={botonMenu} name="boton" src="/images/menu.png" alt="menu" />
                </div>
                <div className="menu flex" ref={menu} name="menu" position="up">
                    <div className="contLogoExtended flex">
                        <Link className="contLinkLogo flex" to="/home#top"><img src="/images/logo2.png" alt="Logo" className="logo"/></Link>
                        <p className="opcionIdioma flex" onClick={toggleIdioma}>{(!isInglish && "English") || (isInglish && "Spanish")}</p>
                    </div>
                
                    <div className="opcionMenu flex" link="/home#top" route="/home" isoption="true">Home</div>
                
                    <div className="opcionMenu flex" name="opcionMenu" link="/experiencia/enfoque#top" route="/experiencia">{(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")}</div>
                    <div className="contSubMenu">
                        <div className="subMenu flex" name="subMenu" position="up" color={colors.experiencia}>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/experiencia/enfoque#top">{(isInglish && "Bilingual and Multicultural Learning") || (!isInglish && "Enfoque Multicultural Bilingüe")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/experiencia/nature#top">{(isInglish && "Nature Based Approach") || (!isInglish && "Aprendizaje en la naturaleza")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/experiencia/comunidad#top">{(isInglish && "School Community ") || (!isInglish && "Comunidad")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/experiencia/atelier#top">{(isInglish && "Atelier ") || (!isInglish && "Cultura del Atelier")}</div>
                        </div>
                    </div>       

                    <div className="opcionMenu flex" name="opcionMenu" link="/programa/grupos#top" route="/programa">{(isInglish && "Our program") || (!isInglish && "Nuestro programa")}</div>
                    <div className="contSubMenu">
                        <div className="subMenu flex" name="subMenu" position="up" color={colors.programa}>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/programa/grupos#top">{(isInglish && "Multi-age groups") || (!isInglish && "Grupos multiedad")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/programa/guia#top">{(isInglish && "Positive guidance ") || (!isInglish && "Guía positiva en resolución de conflictos")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/programa/habitos#top">{(isInglish && "Healthy Food Habits") || (!isInglish && "Hábitos de alimentación saludable")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/programa/bano#top">{(isInglish && "Potty or toilet learning") || (!isInglish && "Aprender a ir al baño")}</div>
                        </div>
                    </div>      

                    <div className="opcionMenu flex" name="opcionMenu" link="/filosofia/nino#top" route="/filosofia">{(isInglish && "Philosophy") || (!isInglish && "Filosofía Educativa")}</div>
                    <div className="contSubMenu">
                        <div className="subMenu flex" name="subMenu" position="up" color={colors.filosofia}>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/filosofia/nino#top">{(isInglish && "Children as protagonists in their learning") || (!isInglish && "El niño como protagonista")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/filosofia/profesor#top">{(isInglish && "Teachers as researchers ") || (!isInglish && "Profesor como investigador")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/filosofia/ambiente#top">{(isInglish && "The learning environment") || (!isInglish && "Ambiente alfabetizador")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/filosofia/documentacion#top">{(isInglish && "Documentation and assessment ") || (!isInglish && "Documentación pedagógica y evaluación")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/filosofia/curriculo#top">{(isInglish && "Emergent curriculum") || (!isInglish && "Currículo emergente")}</div>
                           
                        </div>
                    </div>    
                   
                    <div className="opcionMenu flex" link="/team#top" route="/team" isoption="true">{(isInglish && "Our team") || (!isInglish && "Nuestro Equipo")}</div>

                    <div className="opcionMenu flex" link="/galeria#top" route="/galeria" isoption="true">{(isInglish && "Photo gallery") || (!isInglish && "Galería de Fotos")}</div>
                
                    <div className="opcionMenu flex" name="opcionMenu" link="/admin/proceso#top" route="/admin">{(isInglish && "Enrollment ") || (!isInglish && "Inscripción")}</div>
                    <div className="contSubMenu">
                        <div className="subMenu flex" name="subMenu" position="up" color={colors.admin}> 
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/admin/proceso#top"> {(isInglish && "Enrollment Process") || (!isInglish && "Proceso de inscripción")}</div>
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/admin/horarios#top">{(isInglish && "School Hours and Rates") || (!isInglish && "Horarios y pagos")}</div>    
                            <div className="opcionSubMenu flex" name="opcionSubMenu" link="/admin/calendario#top">{(isInglish && "School Calendar and Events ") || (!isInglish && "Calendario escolar")}</div>
                            {/* <div className="opcionSubMenu flex" name="opcionSubMenu" link="/admin/contacto#top">{(isInglish && "Contact") || (!isInglish && "Contacto")}</div> */}
                            {/* <div className="opcionSubMenu flex" name="opcionSubMenu" link="/admin/work#top">{(isInglish && "Work with us") || (!isInglish && "Trabaja con Nosotros")}</div> */}
                        </div>
                    </div>
                </div>
                <div className="contIconSubMenu">
                    <div className="contIconSM">
                        <p className="nombreSeccion flex"></p>
                        <img className="menuIcon subMenuIcon" ref={botonSubMenu} name="boton" src="/images/menu.png" alt="menu" />
                    </div>
                </div>
            </div>
        </>
    )  
}   

export default NavBar;
