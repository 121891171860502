import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Habitos = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our Program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "Healthy Food Habits") || (!isInglish && "Hábitos de alimentación saludable")}>
                <div className='seccion'>
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/food_seccion/little_creek_school_food_1.jpg" alt="food" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Healthy Food Habits</>) || (!isInglish && <>Hábitos de alimentación saludable</>)}</h2>
                            {(isInglish && 
                            <> 
                                We believe that children need food to connect, socialize, feel joy, and be inspired.<br />
                                We connect children to the food they eat and include them in the preparation of their breakfast and some snacks. This allows them to learn where food comes from, its smells, colors, shapes, and textures, how to measure ingredients, apply math and science notions, fine motor skills, and learn how to create healthy habits. All the food we provide is served to children and adults alike.<br />
                                We prepare and serve all our snacks and meals on-site using minimally processed and packaged foods. Whenever we can we buy food in bulk, local and organic.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                En Little Creek creemos que la conexión de los niños con la comida debe ser a través de la socialización, la inspiración y el disfrute.<br />
                                Conectamos a los niños con los alimentos que comen y los incluimos en la preparación de su desayuno y algunos snacks. Esto les permite aprender de dónde provienen los alimentos, sus aromas, colores, formas y texturas, cómo medir los ingredientes, poner en práctica nociones de matemática y ciencia, habilidades de motricidad fina, y cómo crear hábitos saludables. Toda la comida que ofrecemos se sirve tanto a niños como a adultos.<br />
                                Preparamos y servimos todos nuestros snacks y comidas en el lugar utilizando alimentos mínimamente procesados y envasados. Siempre que podemos compramos alimentos a granel, locales y orgánicos.<br />
                            </>)}
                        </div>
                    </div>
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                All the children eat family style, where we share food around the table, wait our turn, help younger children take their serving, and enjoy a pleasant conversation.<br />
                                When children clean up, they scrape their wasted food into a compost bowl, and actively participate in cleaning up the table and kitchen in different tasks.<br />
                                Children use cutlery, glass and porcelain dishes which are both satisfying to use, convey a sense of respect for the children, and provide clear and accurate feedback about the limits of their use. <br />
                                <span className='color-programa'>Our food curriculum lays a foundation for lifelong health by providing a strong foundation for healthy food habits.</span><br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Todos los niños comen en familia, donde compartimos la comida alrededor de la mesa, esperamos nuestro turno, ayudamos a los más pequeños a tomar su porción y disfrutamos de una agradable conversación.<br />
                                Cuando los niños limpian, colocan los restos de comida en un recipiente de compost y participan activamente en la limpieza de la mesa y la cocina en diferentes tareas.<br />
                                Los niños usan cubiertos de metal, platos de vidrio y porcelana que son satisfactorios de usar, transmiten un sentido de respeto por los niños y brindan información clara y precisa sobre los límites de su uso.<br />
                                <span className='color-programa'>Nuestro plan de estudios sobre alimentación sienta las bases para una salud de por vida al proporcionar una base sólida para hábitos alimentarios saludables.</span><br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/food_seccion/little_creek_school_food_2.jpg" alt="food" />
                        </div>
                    </div>
                                       
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Habitos;
