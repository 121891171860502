import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Bano = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "Potty or toilet learning") || (!isInglish && "Aprender a ir al baño")}>
                <div className='seccion'>
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Potty or toilet learning</>) || (!isInglish && <>Aprender a ir al baño</>)}</h2>
                            {(isInglish && 
                            <> 
                               Our students are not required to be potty trained upon enrolling in our school. We follow the children's developmental needs using the bathroom. We have children who are always in diapers, children who wear pull-ups part-time, and others who only use the potty. We are guided by the needs of children and their families. We are always here for support and will provide gentle encouragement throughout the process.
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                               Nuestros estudiantes no están obligados a aprender a ir al baño para inscribirse en nuestra escuela. Seguimos las necesidades de desarrollo de los niños con el uso del baño. Tenemos niños que siempre usan pañales, niños que usan pull-ups a tiempo parcial y otros que solo usan el baño. Nos guiamos según las necesidades de los niños y sus familias. Siempre estamos aquí para brindarles apoyo, herramientas y alentarlos amablemente durante todo el proceso.
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/potty_seccion/little_creek_school_potty.jpg" alt="potty" />
                        </div>
                    </div>
                           
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Bano;
