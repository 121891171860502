import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import "./grupos.css";

const Grupos = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our Program") || (!isInglish && "Nuestro Programa")} subsecciontitle={(isInglish && "Multi-age groups") || (!isInglish && "Grupos multiedad")}>
                <div className='seccion'>
                    {(isInglish && <h1 className='tituloExp'>Our program</h1>) || (!isInglish  && <h1 className='tituloExp'>Nuestro programa</h1>)}
        
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Multi-age groups</>) || (!isInglish && <>Grupos multiedad</>)}</h2>
                            {(isInglish && 
                            <> 
                                Our program is designed for children between the ages of 2 and 6 in a multi-age group.<br/>
                                <span className='color-programa'>We believe that learning in multi-age groups promotes individualized learning, where children have more flexibility to learn and develop skills at their own pace.</span> <br />
                                A more nurturing environment is created, filled with a variety of ideas and skills.<br/>
                                In multi-age groups, children can find their own guides and playmates, regardless of their skill level. Younger children may perceive older children as helpful peer mentors, they learn more naturally by watching older children enjoy advanced and challenging work and are inspired by that. They look to older children´s guidance, and older children are happy to provide it.<br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Nuestro programa está diseñado para niños de entre 2 y 6 años en un grupo multiedad.<br/>
                                <span className='color-programa'>Creemos que el aprendizaje en grupos multiedad promueve un aprendizaje individualizado, en donde los niños tienen más flexibilidad para aprender y desarrollar habilidades en su propio ritmo. <br /> </span>
                                Se crea un ambiente más enriquecedor, repleto de una variedad de ideas y habilidades.<br/>
                                En los grupos multiedad los niños pueden encontrar sus propios guías y compañeros de juego, independiente de su nivel de habilidad. Los niños más pequeños pueden percibir a los niños más grandes como compañeros mentores útiles, aprenden de una manera más natural observando cómo los niños mayores disfrutan de un trabajo avanzado y desafiante, y esto los inspira. Buscan orientación en los niños mayores, y los niños mayores se complacen en brindarla.<br/>
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/multiage_seccion/little_creek_school_multiage_1.jpg" alt="multiage" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/multiage_seccion/little_creek_school_multiage_2.jpg" alt="multiage" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                After a year or two in the same groups, the children who were the youngest will have the opportunity to practice leadership skills. There is a natural transition from observers to leaders in their own time.<br/>
                                Stronger relationships between families and school are built by having the same teacher for a longer time, the connection between home and school becomes more fluid which is a great benefit to children.<br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Después de un año o dos en los mismos grupos, los niños que eran los más pequeños tendrán la oportunidad de practicar habilidades de liderazgo. Se da una transición natural de observadores a líderes en su propio tiempo.<br/>
                                Se construyen relaciones más fuertes entre las familias y la escuela al tener la misma profesora por más tiempo, la conexión entre el hogar y la escuela se vuelve más fluida lo cual es de gran beneficio para los niños.<br/>
                            </>)}
                            <img src="/images/ardilla.png" className='stickerGrupos' alt="squirrel" />
                        </div>
                    </div>            
                   
                </div>
            </div>   
            <EndNavBar/>
        </>
    );
}

export default Grupos;
