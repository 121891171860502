import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import Form from '../../../form/Form';
import ContactoAdmin from '../contactoAdmin/ContactoAdmin';
import "./admisiones.css";

const Proceso = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>
            <div className='contSeccion contSeccionAdmin flex column' secciontitle={(isInglish && "Enrollment") || (!isInglish && "Inscripción")} subsecciontitle={(isInglish && "Enrollment Process") || (!isInglish && "Proceso de inscripción")}>
                <div className='contTextoYContactoAdmin flex'>
                    <div className='textosAdmin flex column'>
                        {(isInglish && <h1 className='tituloExp tituloAdmin'>Enrollment </h1>) || (!isInglish  && <h1 className='tituloExp tituloAdmin'>Inscripción</h1>)}
                        {(isInglish && <h2 className="titulosExp">Enrollment Process, Tuition and Fees: September 2023 – August 2024</h2>) || (!isInglish  && <h2 className="titulosExp">Proceso de inscripción, matrícula y pagos: Septiembre 2023 – Agosto 2024</h2>)}
                        {(!isInglish && 
                        <>
                            Entendemos que la elección del colegio es una decisión importante y de mucha responsabilidad. En Little Creek tenemos reuniones informativas para que las familias puedan conocer el proyecto educativo y la organización de nuestra escuela.<br/>
                            Para iniciar el proceso de inscripción, les invitamos a completar el Formulario de Interés con su información o enviar un correo electrónico a Fernanda a info@littlecreekschool.com. Nos pondremos en contacto para programar una reunión.<br/>
                            Al aceptar una oferta de inscripción, Little Creek School requiere un depósito de $150 junto con el Formulario de Información y Acuerdo de Inscripción completo y firmado para asegurar su vacante en nuestro programa.<br/>
                            Puede unirse a nuestra lista de espera. Complete una Solicitud de Lista de Espera y envíela a nuestro correo electrónico de contacto info@littlecreekschool.com<br/>
                        </>)
                        || 
                        (isInglish  &&             
                        <>
                            We understand that the choice of school is an important decision and one with a lot of responsibility. At Little Creek we have informative meetings so that families can learn about the educational project and the school organization.<br/>
                            To initiate the enrollment process, we invite you to complete the Interest Form with your information or email Fernanda at info@littlecreekschool.com. We will be in contact to schedule a meeting.<br/>
                            Upon accepting an enrollment offer, Little Creek School requires a registration fee of $150 along with a completed and signed Enrollment Information and Agreement Form to secure your position in our program.<br/>
                            You may join our waitlist. Please complete a Waitlist Application and send it to our contact email info@littlecreekschool.com<br/>
                        </>)}
                    </div>
                    <div className='contContactoAdmin flex'>
                        <ContactoAdmin/>     
                    </div>
                </div>
                <Form/> 
            </div>  
            <EndNavBar />
        </>
    );
}

export default Proceso;
