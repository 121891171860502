import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import ContactoAdmin from '../contactoAdmin/ContactoAdmin';
import "./horarios.css";

const Horarios = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>  
            <div className='contSeccion contSeccionAdmin flex column' secciontitle={(isInglish && "Enrollment") || (!isInglish && "Administracion")} subsecciontitle={(isInglish && "School Hours and Rates") || (!isInglish && "Horarios y pagos")}>
                <div className='contTextoYContactoAdmin flex'>
                    <div className='textosAdmin flex column'>
                        {(isInglish && <h2 className="titulosExp tituloCalendario">School Hours and Rates</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Horarios y pagos</h2>)}
                        {(isInglish && 
                        <>
                            Our school hours are Monday - Thursday from 8:00 - 4pm. Please see our rates below. Post enrollment, we have no additional fees throughout the school year, except for school trips.<br/>
                            Our monthly rates for 2023-2024 are: <br />

                            <b>Half Day Program (8:00 – 12:00)</b><br />
                            2 days (Mon, Wed) (Tue, Thu) $ 310 <br />
                            4 days (Mo, Tue, Wed, Thu) $ 540<br />
                            Half day program includes all materials, healthy breakfast, and morning snack. <br />
                            Option for extended hour $10 per extra hour. For extended hours families provide lunch for their children. <br />
                            <b>Full Day Program (8:00 – 4:00)</b><br />
                            2 days (Mon, Wed) (Tue, Thu) $ 515<br />
                            4 days (Mo, Tue, Wed, Thu) $ 930<br />
                            Full day program includes all materials, healthy breakfast, morning snack, and afternoon snack. <br />
                            Option for extended hours (5:00 pm) $10 per extra hour. Families provide lunch for their children. <br />

                            For your convenience, tuition is divided into twelve equal monthly installments and due on the 1st of the month. <br/><br />
                            {/* *We are registered through Oregon Department of Human Services (DHS) childcare tuition reimbursements.<br/>
                            For More information and to apply for financial help with child care costs, please visit:<br/> */}
                        </>)
                        || 
                        (!isInglish  &&             
                        <>
                            Nuestro horario escolar es de lunes a jueves de 8:00 a 4:00 p.m. Consulte nuestras tarifas a continuación. Después de la inscripción, no tenemos tarifas adicionales durante todo el año escolar, a excepción de los viajes escolares.<br/>
                            Nuestras tarifas mensuales para 2023-2024 son: <br />
                            
                            <b>Programa medio día (8:00 am – 12:00 pm)</b><br/>
                            2 días (Lun, Mie) (Mar, Jue) $ 310 <br/>
                            4 días (Lun, Mar, Mie, Jue) $ 540<br/>
                            Los programas de medio día incluyen todos los materiales, un desayuno saludable, y un snack a media mañana. <br/>
                            Tenemos opciones de horario extendido con un cargo adicional de $10 por hora extra. Las familias de horario extendido deben traer el almuerzo. <br/>
                            <b>Programa día completo (8:00 am – 4:00 pm)</b><br/>
                            2 días (Lun, Mie) (Mar, Jue) $ 515<br/>
                            4 días (Lun, Mar, Mie, Jue) $ 930<br/>
                            Los programas de día completo incluyen todos los materiales, un desayuno saludable, un snack a media mañana, y un snack a media tarde.<br/> 
                            Tenemos opciones de horario extendido (5:00 pm) con un cargo adicional de $10 por hora extra. Las familias deben traer el almuerzo. <br/>
                            
                            Para su comodidad, la matrícula se divide en doce cuotas mensuales iguales y vence el primer día del mes.<br/>
                            {/* *Estamos registrados a través de los reembolsos de matrícula de cuidado infantil del Departamento de Servicios Humanos de Oregón (DHS).<br/>
                            Para obtener más información y solicitar ayuda financiera con los costos de cuidado infantil, visite: <br/>  */}
                        </>)}
                        {/* <a href="https://www.oregon.gov/DHS/assistance/CHILD-CARE/Pages/parents.aspx" target="_blank" className='linkAdmis' rel="noreferrer">https://www.oregon.gov/DHS/assistance/CHILD-CARE/Pages/parents.aspx</a> */}
                    </div>
                    <div className='contContactoAdmin flex'>
                        <ContactoAdmin/>     
                    </div>
                </div>
            </div>  
            <EndNavBar />
        </>
    );
}

export default Horarios;
