import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import "./profesor.css";

const Profesor = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Philosophy") || (!isInglish && "Filosofía Educativa")} subsecciontitle={(isInglish && "Teachers as researchers") || (!isInglish && "Profesor como investigador")}>
                <div className='seccion'>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Teachers as researchers</>) || (!isInglish && <>Profesor como investigador</>)}</h2>
                            {(isInglish && 
                            <>
                                <span className='color-filosofia'>At Little Creek we believe that the teacher is a guide, researcher and collaborator who must accompany the exploration of different projects and experiences through careful observation, active listening, and documentation of each child's work.</span><br />
                                The teacher asks questions, makes comments, and encourages children to ask questions. Challenges children to explore their own ideas and conduct experiments. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span className='color-filosofia'>En Little Creek creemos que el profesor es un guía, investigador y colaborador que debe acompañar la exploración de diferentes proyectos y experiencias a través de la observación cuidadosa, la escucha activa y la documentación del trabajo de cada niño.</span><br />
                                El profesor hace preguntas, hace comentarios y anima a los niños a hacer preguntas. Desafía a los niños a explorar sus propias ideas y realizar experimentos. <br />
                            </>)}
                        </div>
                        <div className='contImgSecciones flex'>
                            <img className='imgSecciones' src="/images/teachers_seccion/little_creek_school_teachers_1.jpg" alt="teachers" />
                        </div>
                    </div>     

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones flex'>
                            <img className='imgSecciones' src="/images/teachers_seccion/little_creek_school_teachers_2.jpg" alt="teachers" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {/* <h2 className="titulosExp">{(isInglish && <>Teachers as researchers</>) || (!isInglish && <>Profesor como investigador</>)}</h2> */}
                            {(isInglish && 
                            <>
                                Helps children become close observers of their surroundings, paying close attention to different perspectives and details. Plans starting points for children to explore and provides open-ended resources that encourage children to develop their own thinking and ways of learning.
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Ayuda a los niños a convertirse en observadores cercanos del entorno, prestando especial atención a las diferentes perspectivas y detalles. Planifica puntos de partida para que los niños exploren y proporciona recursos abiertos que anime a los niños a desarrollar su propio pensamiento y formas de aprender.        
                            </>)}
                            <img src="/images/conejo.png" className='stickerProfesor' alt="rabbit" />
                        </div>
                    </div>      

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Profesor;
