import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Curriculo = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Philosophy") || (!isInglish && "Filosofía Educativa")} subsecciontitle={(isInglish && "Emergent curriculum") || (!isInglish && "Currículo emergente")}>
                <div className='seccion'>
                  
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opc' src="/images/curriculum_seccion/little_creek_school_curriculum_1.jpg" alt="curriculum" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Emergent curriculum</>) || (!isInglish && <>Currículo emergente</>)}</h2>
                            {(isInglish && 
                            <> 
                                Our curriculum is emergent and flexible. <br />
                                <span className='color-filosofia'>Our experiences, research and projects arise from children's questions and ideas that are used as starting points for developing their learning.</span><br />
                                In our program we find a balance of child and teacher directed activities, extended periods of uninterrupted play time and lots of hands-on activities. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Nuestro currículo es emergente y flexible. <br />
                                <span className='color-filosofia'>Nuestras experiencias, investigaciones y proyectos surgen de las preguntas e intereses de los niños que se utilizan como puntos de partida para desarrollar su aprendizaje.</span><br />
                                En nuestro programa encontramos un equilibrio de actividades dirigidas por los niños y los profesores, períodos prolongados de tiempo de juego ininterrumpido y muchas actividades prácticas. <br />
                            </>)}
                        </div>
                    </div>  

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                We carefully observe children's spontaneous work and play, incorporating it into objects and experiences that we think will intrigue them. Together, the teacher and children conduct long-term projects or investigations that generate and extend children's ideas. Children are encouraged to ask new questions, to discover answers through active exploration, and to express their ideas in many different media.
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Observamos cuidadosamente el trabajo y el juego espontáneo de los niños, y los incorporamos a objetos y experiencias que creemos que les intrigan. Juntos, el maestro y los niños realizan proyectos o investigaciones a largo plazo que generan y amplían las ideas de los niños. Se alienta a los niños a hacer nuevas preguntas, descubrir las respuestas a través de la exploración activa y expresar sus ideas en muchos medios diferentes.
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/curriculum_seccion/little_creek_school_curriculum_2.jpg" alt="curriculum" />
                        </div>
                    </div>  

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/curriculum_seccion/little_creek_school_curriculum_3.jpg" alt="curriculum" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                This type of learning is important because it allows children to develop cooperative work skills, such as communication, leadership and problem solving, promotes innovation and creativity, self-determination and self-regulation, and tolerance for ambiguity; concepts that are fundamental in the education of the 21st century, in a globalized and changing world.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Este tipo de aprendizaje es importante porque permite que los niños desarrollen habilidades de trabajo cooperativo, como la comunicación, el liderazgo y la resolución de problemas, promueve la innovación y la creatividad, la autodeterminación y autorregulación, y la tolerancia a la ambigüedad; aprendizajes que son fundamentales en la educación del siglo XXI, en un mundo globalizado y cambiante.<br />
                            </>)}
                        </div>
                    </div>  
                   

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Curriculo;
