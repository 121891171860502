import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../context/IdiomaContext";
import "./team.css";
import EndNavBar from '../../endNavBar/EndNavBar';

const Team = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our Team") || (!isInglish && "Nuestro Equipo")}>
                <div className='seccion seccionTeam' id="team"> 
                    {(isInglish && <h2 className="tituloExp tituloTeam">Director and Lead Teacher. <br/> Fernanda Lucas De La Torre</h2>) || (!isInglish  && <h2 className="tituloExp tituloTeam">Fernanda Lucas De La Torre. <br/> Directora / Profesora líder</h2>)}
                    {/* <div className='contPerfil teamContFoto flex column OoS'>
                        <div className='contImgEquipo'>
                            <img className='imgEquipo' src="images/team/ot.jpg" alt="" />
                        </div>
                    </div> */}

                    <div className='contImgYParrafosSecciones'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_1.jpg" alt="team" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                My adventure with education began when I was studying Dramatic Art in Buenos Aires (Argentina). In this artistic experience I discovered the importance of developing creativity from the first years of life and its relevance in the integral development of the human being. With this inspiration I decided to study education.<br/>
                                From the first day at the university, I realized that this was my vocation and my path in this world. <br />
                                <span className='color-team'>I have a degree in Early Childhood Education with a specialty in artistic - expressive languages and a degree in General Education.</span><br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Mi aventura con la educación comenzó cuando estudiaba Arte Dramático en Buenos Aires (Argentina). En esta experiencia artística descubrí la importancia de desarrollar la creatividad desde los primeros años de vida y su relevancia en el desarrollo integral del ser humano. Con esta inspiración decidí estudiar educación.<br/>
                                Desde el primer día en la universidad me di cuenta de que esa era mi vocación y mi camino en este mundo. <br />
                                <span className='color-team'>Soy Licenciada en Educación Infantil con especialidad en lenguajes artístico - expresivos y Licenciada en Educación General.</span><br/>
                            </>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                I have 10 years of experience teaching in children's creative and international schools, in positions as lead teacher, academic coordinator, Spanish teacher, and in charge of theater, artistic installations, and creative writing workshops. <br />
                                I’d like to continue learning, training, and developing as a person and am constantly looking for new workshops and professional developments.<br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <> 
                                Tengo 10 años de experiencia dando clases en escuelas de creatividad infantil y colegios internacionales, en cargos como profesora líder, coordinadora académica, profesora de español, y a cargo de talleres de teatro, instalaciones artísticas y escritura creativa. <br />
                                Me gusta seguir aprendiendo, formándome y desarrollándome como persona, constantemente estoy buscando nuevos talleres y capacitaciones profesionales.<br/>
                            </>)}
                            <img src="/images/hoja.png" className='stickerTeam' alt="leaf" />
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_2.jpg" alt="team" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_3.jpg" alt="team" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                I was born and raised in the beautiful city of Quito (Ecuador) surrounded by mountains and volcanoes. Thanks to my experiences living and teaching in a diversity of countries such as Ecuador, Argentina, Poland and now the United States, I have learned to be a flexible, creative, and adventurous person. <br />
                                I moved to Coos Bay, Oregon in 2021 and live in the middle of a forest with my son Mateo and my husband Seth. Along with them I continue to explore and discover new places.<br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <> 
                                Nací y crecí en la hermosa ciudad de Quito (Ecuador) rodeada de montañas y volcanes. Gracias a mis experiencias viviendo y enseñando en una diversidad de países como Ecuador, Argentina, Polonia y ahora los Estados Unidos, he aprendido a ser una persona flexible, creativa y aventurera. <br />
                                Me mudé a Coos Bay , Oregón en 2021, vivo en medio de un bosque con mi hijo Mateo y mi esposo Seth. Junto a ellos sigo explorando y descubriendo nuevos lugares.<br/>
                            </>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                I am very excited with the possibility of sharing the Little Creek experience with new children and families in our community.<br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <> 
                                Estoy muy emocionada de poder compartir la experiencia de Little Creek con nuevos niños y familias de nuestra comunidad.<br/>
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/team_seccion/little_creek_school_team_4.jpg" alt="team" />
                        </div>
                    </div>

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Team;
