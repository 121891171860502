import Home from "./components/home/Home";
import NavBar from "./components/navbar/NavBar";
import { Route , Routes, BrowserRouter } from "react-router-dom";
import Team from "./components/secciones/team/Team";
import Proceso from "./components/secciones/administracion/proceso/Proceso";
import Calendario from "./components/secciones/administracion/calendario/Calendario";
import Footer from "./components/footer/Footer";
// import Contacto from "./components/secciones/administracion/contacto/Contacto";
import Enfoque from "./components/secciones/experiencia/enfoque/Enfoque";
import Nature from "./components/secciones/experiencia/nature/Nature";
import Comunidad from "./components/secciones/experiencia/comunidad/Comunidad";
import Guia from "./components/secciones/programa/guia/Guia";
import Nino from "./components/secciones/filosofia/nino/Nino";
import Profesor from "./components/secciones/filosofia/profesor/Profesor";
import Ambiente from "./components/secciones/filosofia/ambiente/Ambiente";
import Documentacion from "./components/secciones/filosofia/documentacion/Documentacion";
import Curriculo from "./components/secciones/filosofia/curriculo/Curriculo";
import Atelier from "./components/secciones/experiencia/atelier/Atelier";
import { Navigate } from "react-router-dom";
import Grupos from "./components/secciones/programa/gupos/Grupos";
import Habitos from "./components/secciones/programa/habitos/Habitos";
import Bano from "./components/secciones/programa/bano/Bano";
import Horarios from "./components/secciones/administracion/horarios/Horarios";
import Work from "./components/secciones/administracion/work/Work";
import Galeria from "./components/secciones/galeria/Galeria";

function App() {
    return (
        <>  <BrowserRouter>
                <NavBar/>
                <Routes>
                    <Route path="/home" element={<Home/>} />

                    <Route path="/programa/grupos" element= {<Grupos/>} />
                    <Route path="/programa/guia" element= {<Guia/>} />
                    <Route path="/programa/habitos" element= {<Habitos/>} />
                    <Route path="/programa/bano" element= {<Bano/>} />

                    <Route path="/experiencia/enfoque" element= {<Enfoque/>} />
                    <Route path="/experiencia/nature" element= {<Nature/>} />
                    <Route path="/experiencia/comunidad" element= {<Comunidad/>} />
                    <Route path="/experiencia/atelier" element= {<Atelier/>} />

                    <Route path="/filosofia/nino" element= {<Nino/>} />
                    <Route path="/filosofia/profesor" element= {<Profesor/>} />
                    <Route path="/filosofia/ambiente" element= {<Ambiente/>} />
                    <Route path="/filosofia/documentacion" element= {<Documentacion/>} />
                    <Route path="/filosofia/curriculo" element= {<Curriculo/>} />
                    
                    <Route path="/team" element= {<Team/>} />
                    
                    <Route path="/admin/proceso" element= {<Proceso/>} />
                    <Route path="/admin/horarios" element= {<Horarios/>} />
                    <Route path="/admin/calendario" element= {<Calendario/>} />
                    {/* <Route path="/admin/contacto" element= {<Contacto/>} /> */}
                    <Route path="/admin/work" element= {<Work/>} />

                    <Route path="/galeria" element= {<Galeria/>} />

                    <Route path="*" element= {<Navigate to="/home" />} />
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    );
}

export default App;
