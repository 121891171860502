import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Nino = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return ( 
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Philosophy") || (!isInglish && "Filosofía Educativa")} subsecciontitle={(isInglish && "Children as protagonists in their learning") || (!isInglish && "El niño como protagonista")}>
                <div className='seccion'>
                    {(isInglish && <h1 className='tituloExp'>Philosophy</h1>) || (!isInglish && <h1 className='tituloExp tituloNino'>Filosofía Educativa</h1>)}

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opt' src="/images/children_seccion/little_creek_school_children_1.jpg" alt="children" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                <span> Our educational perspective is inspired by the <a href="https://www.reggiochildren.it/en/reggio-emilia-approach/valori-en/" target="_blank" className='linkAdmis' rel="noreferrer">Reggio Emilia philosophy</a> and other educational philosophies that seek an innovative and transformative vision of current education. We believe in the potential of children as protagonists of their own learning, active children, critics, and creators of culture, with their own rights and capacities.</span><br />
                                <span className='color-filosofia'>We believe that children learn better through interactive experiences based on their real interests, respecting their time and diverse ways of learning.</span> <br />
                                Children have many languages in art, music, and drama, and they should be encouraged to learn through experiences like listening, moving, touching, and observing. Play is an essential component by which children develop their socio-emotional, cognitive, sensory, creative, and physical skills, and through it they discover and experience the world around them.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span> Nuestra perspectiva educativa está inspirada en la <a href="https://www.reggiochildren.it/en/reggio-emilia-approach/valori-en/" target="_blank" className='linkAdmis' rel="noreferrer">Filosofía Reggio Emilia</a> y otras filosofías educativas que buscan una visión innovadora y transformadora de la educación actual. Creemos en el potencial de los niños como protagonistas de su propio aprendizaje, niños activos, críticos y creadores de cultura, con derechos y capacidades propias.</span><br />
                                <span className='color-filosofia'>Creemos que los niños aprenden mejor a través de experiencias interactivas basadas en sus intereses reales, respetando sus tiempos y las diversas formas de aprender. </span> <br />
                                Los niños tienen muchos lenguajes en el arte, la música y la dramatización, y se les debe alentar a aprender a través de experiencias como escuchar, moverse, tocar y observar.<br />
                                El juego es un componente esencial mediante el cual los niños desarrollan sus habilidades socioemocionales, cognitivas, sensoriales, creativas y físicas, y a través de él descubren y experimentan el mundo que les rodea.<br />
                            </>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Children as protagonists in their learning</>) || (!isInglish && <>El niño como protagonista de su aprendizaje</>)}</h2>
                            {(isInglish && 
                            <>
                                Children are active, capable, and valuable members of our community, citizens of the world with rights and duties. They are authors and builders of their learning, with extraordinary potentialities that are manifested in an unceasing exchange with the cultural and social context. They have their own ideas, express opinions, make independent choices and can play and work well with others.<br />
                                <span className='color-filosofia'>At Little Creek we invite children to explore, question and interpret the world, and create a solid foundation of experiences to guide them in reaching their full potential.</span> <br /> 
                                We encourage children to learn from one another. We provide spaces where they work and play together in small groups from a very early age. We promote exchanges to foster listening skills for other points of view and to respect the opinions and feelings of others.<br /> 
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Los niños son miembros activos, capaces y valiosos de nuestra comunidad, ciudadanos del mundo con derechos y deberes. Son autores y constructores de sus aprendizajes, con extraordinarias potencialidades que se manifiestan en un incesante intercambio con el contexto cultural y social. Tienen sus propias ideas, expresan opiniones, toman decisiones independientes y pueden jugar y trabajar bien con otros.<br />
                                <span className='color-filosofia'>En Little Creek invitamos a los niños a explorar, cuestionar e interpretar el mundo y crear una base sólida de experiencias para guiarlos a alcanzar su máximo potencial.</span> <br />
                                Animamos a los niños a aprender unos de otros. Brindamos espacios donde trabajan y juegan juntos en pequeños grupos desde una edad muy temprana. Promovemos los intercambios para fomentar la capacidad de escuchar otros puntos de vista y respetar las opiniones y sentimientos de los demás.<br />
                            </>)}
                        </div>
                        <div className='contImgSecciones flex'>
                            <img className='imgSecciones' src="/images/children_seccion/little_creek_school_children_2.jpg" alt="children" />
                        </div>
                    </div>     

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Nino;
