import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Documentacion = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Philosophy") || (!isInglish && "Filosofía Educativa")} subsecciontitle={(isInglish && "Documentation and assessment") || (!isInglish && "Documentación pedagógica y evaluación")}>
                <div className='seccion'>
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Documentation and assesment</>) || (!isInglish && <>Documentación pedagógica y evaluación</>)}</h2>
                            {(isInglish && 
                            <> 
                                <span className='color-filosofia'>In our school, pedagogical documentation makes learning visible.</span> <br />
                                It is a powerful tool to reflect and give visibility to learning processes, it is an evaluation instrument, which evidences and records the history of each child and her/his interactions. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span className='color-filosofia'>En nuestra escuela, la documentación pedagógica hace que el aprendizaje sea visible. </span><br />
                                Es una herramienta potente para reflexionar y dar visibilidad a los procesos de aprendizaje, es un instrumento de evaluación, que evidencia y registra la historia de cada niño y sus interacciones. <br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opr' src="/images/documentation_seccion/little_creek_school_documentation_1.jpg" alt="documentation" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/documentation_seccion/little_creek_school_documentation_2.jpg" alt="documentation" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                It is the written, photographic, and audiovisual record of the processes and productions through which children display reflections, knowledge, and content. From there, we begin the design of projects of these meaningful processes, motivating and contextualizing for each group or small group. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>                               
                                Es el registro escrito, fotográfico y audiovisual de los procesos y producciones a través de los cuales los niños despliegan reflexiones, conocimientos y contenidos. A partir de ahí, comenzamos el diseño de proyectos de estos procesos, que sea significativo, motivador y contextualizado para cada grupo o pequeño grupo. <br />
                            </>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                We find creative ways to display their learning process so they can appreciate their own and each other’s work, and so parents and community visitors can see and appreciate what they've been doing. Children will often work together to create classroom displays and collective art projects to showcase their work and learning. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>                               
                                Encontramos formas creativas de mostrar su proceso de aprendizaje para que puedan apreciar su propio trabajo y el de los demás, y para que los padres y los visitantes de la comunidad puedan conocer y apreciar lo que han estado haciendo. Los niños a menudo trabajarán juntos para crear exhibiciones en el aula y proyectos de arte colectivo para mostrar su trabajo y aprendizaje. <br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/documentation_seccion/little_creek_school_documentation_3.jpg" alt="documentation" />
                        </div>
                    </div>
                                           
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Documentacion;
