import React from 'react';
import "./endNavBar.css";
import { useContext, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { IdiomaContext } from "../../context/IdiomaContext";

const EndNavBar = () => {
    const {isInglish} = useContext(IdiomaContext);          
    
    const colors = {
        experiencia: getComputedStyle(document.documentElement).getPropertyValue('--color-experiencia'),
        filosofia: getComputedStyle(document.documentElement).getPropertyValue('--color-filosofia'),
        programa: getComputedStyle(document.documentElement).getPropertyValue('--color-programa'),
        admin: getComputedStyle(document.documentElement).getPropertyValue('--color-admin'),
        galeria: getComputedStyle(document.documentElement).getPropertyValue('--color-galeria'),
        team: getComputedStyle(document.documentElement).getPropertyValue('--color-team')
    }
    
    const links = [
        {link: "home", desc:"Home", color:"rgb(75, 132, 238)"}, 

        {link:"experiencia/enfoque", color: colors.experiencia, desc:(isInglish && "Bilingual and Multicultural Learning") || (!isInglish && "Enfoque Multicultural BilingüeNature")},
        {link:"experiencia/nature", color: colors.experiencia, desc:(isInglish && "Nature Based Approach") || (!isInglish && "Aprendizaje en la naturaleza")},
        {link:"experiencia/comunidad", color: colors.experiencia, desc:(isInglish && "School Community") || (!isInglish && "Comunidad")},
        {link:"experiencia/atelier", color: colors.experiencia, desc:(isInglish && "Atelier") || (!isInglish && "Cultura del Atelier")}, 
                
        {link:"programa/grupos", color: colors.programa, desc:(isInglish && "Multi-age groups") || (!isInglish && "Grupos multiedad")},
        {link:"programa/guia", color: colors.programa, desc:(isInglish && "Positive guidance") || (!isInglish && "Guía positiva en resolución de conflictos")},
        {link:"programa/habitos", color: colors.programa, desc:(isInglish && "Healthy Food Habits") || (!isInglish && "Hábitos de alimentación saludable")},
        {link:"programa/bano", color: colors.programa, desc:(isInglish && "Potty or toilet learning") || (!isInglish && "Aprender a ir al baño")},

        {link:"filosofia/nino", color: colors.filosofia, desc:(isInglish && "Children as protagonists in their learning") || (!isInglish && "El niño como protagonista")},
        {link:"filosofia/profesor", color: colors.filosofia, desc:(isInglish && "Teachers as researchers") || (!isInglish && "Profesor como investigador")},
        {link:"filosofia/ambiente", color: colors.filosofia, desc:(isInglish && "The learning environment") || (!isInglish && "Ambiente alfabetizador")},
        {link:"filosofia/documentacion", color: colors.filosofia, desc:(isInglish && "Documentation and assessment") || (!isInglish && "Documentación pedagógica y evaluación")},
        {link:"filosofia/curriculo", color: colors.filosofia, desc:(isInglish && "Emergent curriculum") || (!isInglish && "Currículo emergente")}, 
                      
        {link:"team", color: colors.team, desc:(isInglish && "Our team") || (!isInglish && "Nuestro Equipo")}, 
        
        {link:"galeria", color: colors.galeria, desc:(isInglish && "Photo gallery") || (!isInglish && "Galería de Fotos")},

        {link:"admin/proceso", color: colors.admin, desc:(isInglish && "Enrollment Process") || (!isInglish && "Proceso de inscripción")}, 
        {link:"admin/horarios", color: colors.admin, desc:(isInglish && "School Hours and Rates") || (!isInglish && "Horarios y pagos")}, 
        {link:"admin/calendario", color: colors.admin, desc:(isInglish && "School Calendar and Events ") || (!isInglish && "Calendario - Ciclo Lectivo")}, 
        // {link:"admin/contacto", color: colors.admin, desc:(isInglish && "Contact") || (!isInglish && "Contacto")}, 
        // {link:"admin/work", color: colors.admin, desc:(isInglish && "Work with us") || (!isInglish && "Trabaja con Nosotros")},  
    ];

    const getOtherPage = () => {
        const actualPage  = window.location.href;
        const actualPositionInArray = links.findIndex((link) => actualPage.includes(link.link));            
        let nextPage = links[actualPositionInArray + 1];
        let prevPage = links[actualPositionInArray - 1];      
        if (actualPositionInArray === 0) {
            prevPage = links[links.length - 1]
        }
        if (actualPositionInArray === links.length - 1) {
            nextPage = links[0]
        }
        return {prevPage, nextPage};
    } 

    useEffect(() => {
        document.querySelector(".contAnterior").style.backgroundColor = getOtherPage().prevPage.color;
        document.querySelector(".contSiguiente").style.backgroundColor = getOtherPage().nextPage.color;
        document.querySelector(".fondoAnterior").style.backgroundColor = getOtherPage().prevPage.color;
        document.querySelector(".fondoSiguiente").style.backgroundColor = getOtherPage().nextPage.color;
        if (window.location.href.includes("home")) {
            document.querySelector(".contAnterior").style.backgroundColor = colors.admin;   //Si estamos en home ponemos el color del boton anterior correspondiente al color de administracion
        }
        // eslint-disable-next-line
    }, []);   

    return (

        !window.location.href.includes("home") ?        //Si no estamos en home...

        <div className='contEndNavBar flex wrap OoS'>
            <div className='fondoAnterior'></div>
            <HashLink to={`/${getOtherPage().prevPage.link}#top`} smooth={true} className="contAnterior flex">
                <div className='anterior flex column'>
                    <p> ◄ {(isInglish && "Previous") || (!isInglish && "Anterior")} </p>
                    <h3>{getOtherPage().prevPage.desc}</h3>
                </div>
            </HashLink>
            <div className='fondoSiguiente'></div>
            <HashLink to={`/${getOtherPage().nextPage.link}#top`} smooth={true} className="contSiguiente flex">
                <div className='siguiente flex column'>
                    <p> {(isInglish && "Next") || (!isInglish && "Siguiente")} ► </p>
                    <h3>{getOtherPage().nextPage.desc}</h3>
                </div>
            </HashLink>
        </div>

        :                                               //Si estamos en home...

        <div className='contEndNavBar flex wrap OoS'>     
            <div className='fondoAnterior'></div>                                    
            <HashLink to="/admin/proceso#top" smooth={true} className="contAnterior flex">
                <div className='anterior flex column'>
                    <p> ◄ {(isInglish && "Previous") || (!isInglish && "Anterior")} </p>
                    <h3> {(isInglish && "Enrollment Process") || (!isInglish && "Proceso de inscripción")} </h3>
                </div>
            </HashLink>
            <div className='fondoSiguiente'></div>
            <HashLink to="/experiencia/enfoque#top" smooth={true} className="contSiguiente flex">
                <div className='siguiente flex column'>
                    <p> {(isInglish && "Next") || (!isInglish && "Siguiente")} ► </p>
                    <h3> {(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} </h3>
                </div>
            </HashLink>
        </div>

    );
}

export default EndNavBar;
