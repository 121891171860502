import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Ambiente = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Philosophy") || (!isInglish && "Filosofía Educativa")} subsecciontitle={(isInglish && "The learning environment") || (!isInglish && "Ambiente alfabetizador")}>
                <div className='seccion'>
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opr' src="/images/enviroment_seccion/little_creek_school_enviroment_1.jpg" alt="enviroment" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>The learning environment</>) || (!isInglish && <>Ambiente alfabetizador</>)}</h2>
                            {(isInglish && 
                            <> 
                                The environment is a living and inspiring space, which interacts, changes, and takes shape in relation to projects and learning experiences. <br />
                                <span className='color-filosofia'>We want our space to reflect the culture of our school, our children, and their families.</span><br />
                                At Little Creek, learning spaces are carefully prepared and designed to maximize opportunities to invite children to explore, create and learn  in a safe and nurturing environment. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                El ambiente es un espacio vivo e inspirador, que interactúa, se modifica y toma forma en relación con los proyectos y experiencias de aprendizaje. <br />
                                <span className='color-filosofia'>Queremos que nuestro espacio refleje la cultura de nuestra escuela, de nuestros niños y sus familias.</span><br />
                                En Little Creek los espacios de aprendizaje están cuidadosamente preparados y diseñados en un esfuerzo por maximizar las oportunidades para invitar a los niños a explorar, crear y aprender, en un ambiente seguro y acogedor. <br />
                            </>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                We believe that children thrive in spaces that offer organization and predictability, we design a space that conveys a sense of beauty, order, and calmness, where they can be more independent and feel safe. Everything is selected with the intention of creating communications, exchanges, and interactions. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>  
                                Creemos que los niños prosperan en espacios que ofrecen organización y previsibilidad, diseñamos un espacio que transmite una sensación de belleza, orden y calma, en el que pueden ser más independientes y sentirse seguros. Todo es seleccionado con la intención de crear comunicaciones, intercambios e interacciones. <br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opr' src="/images/enviroment_seccion/little_creek_school_enviroment_2.jpg" alt="enviroment" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opr' src="/images/enviroment_seccion/little_creek_school_enviroment_3.jpg" alt="enviroment" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                               
                                In our selection of materials and toys, we include elements such as wood, bamboo, glass, metal, ceramic, silk, and cotton; natural materials such as rocks, sticks, leaves, and plants; and we also work with recycled materials such as cardboard, cork, plastic, and metal.<br />
                                Incorporating elements of nature and beauty into our school and also carefully selecting artwork to display at children's eye level, their own artwork, and photographs.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>  
                                
                                En nuestra selección de materiales y juguetes, incluimos materiales naturales como madera, bambú, vidrio, metal, cerámica, seda y algodón; materiales naturales como rocas, palos, hojas y plantas; y también trabajamos con materiales reciclados como cartón, corcho, plástico y metal.<br />
                                La incorporación de elementos de la naturaleza y la belleza en nuestra escuela también está seleccionando cuidadosamente obras de arte para exhibirlas a la altura de los ojos de los niños, sus propias obras de arte y fotografías.<br />
                            </>)}
                        </div>
                    </div>            
                                 
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Ambiente;
