import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Atelier = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Atelier") || (!isInglish && "Cultura del Atelier")}>
                <div className='seccion'>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/atelier_seccion/little_creek_school_atelier_1.jpg" alt="atelier" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>The Atelier</>) || (!isInglish && <>Cultura del Atelier</>)}</h2>
                            {(isInglish && 
                            <> 
                                At Little Creek, we believe that all children are naturally creative and should have opportunities to develop their creative and expressive abilities.<br />
                                <span className='color-experiencia'>Our school is seen as a big Atelier where children have spaces where they can imagine, experiment, create and express themselves through different media, materials, and artistic languages. </span> <br />
                                Music, dance, visual arts, and theater experiences are encouraged.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                En Little Creek creemos que todos los niños son naturalmente creativos y deben tener oportunidades para desarrollar sus habilidades creativas y de expresión.<br />
                                <span className='color-experiencia'>Nuestra escuela pensada como un gran Atelier en donde los niños tienen espacios donde pueden imaginar, experimentar, crear y expresarse por medio de diferentes medios, materiales y lenguajes artísticos. </span> <br />
                                Se fomentan experiencias de música, danza, artes visuales y teatro.<br />
                            </>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                In different projects during the year, we invite artists and artisans from our community to share their knowledge and skills in different projects; experts who will guide and teach children about the different artistic expressions, media, and materials by which they can express their ideas and bring the abstract to the concrete world.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                En diferentes proyectos durante el año invitamos a artistas y artesanos de nuestra comunidad a compartir sus conocimientos y habilidades en diferentes proyectos; expertos que guiarán y enseñarán a los niños sobre las diferentes expresiones artísticas, medios y materiales por los cuales pueden expresar sus ideas, y llevar el mundo abstracto al mundo concreto.<br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/atelier_seccion/little_creek_school_atelier_2.jpg" alt="atelier" />
                        </div>
                    </div>

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Atelier;
