import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Guia = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "Positive guidance") || (!isInglish && "Guía positiva en resolución de conflictos")}>
                <div className='seccion'>
                 
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Positive guidance</>) || (!isInglish && <>Guía positiva en resolución de conflictos</>)}</h2>
                            {(isInglish && 
                            <> 
                                Our goal at Little Creek is to help our children develop strong social and emotional skills. We believe that children with strong social-emotional skills tend to be happier, show greater motivation to learn, have a more positive attitude, more eagerly participate in daily experiences, and are able to establish and maintain positive relationships with adults and peers.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Nuestro objetivo en Little Creek es ayudar a nuestros niños a desarrollar sólidas habilidades sociales y emocionales. Creemos que los niños con fuertes habilidades socioemocionales tienden a ser más felices, muestran una mayor motivación para aprender, tienen una actitud más positiva, participan con más entusiasmo en las experiencias cotidianas y pueden establecer y mantener relaciones positivas con adultos y compañeros.<br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/guidance_seccion/little_creek_school_guidance_ 1.jpg" alt="guidance" />
                        </div>
                    </div> 

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/guidance_seccion/little_creek_school_guidance_ 2.jpg" alt="guidance" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                <span className='color-programa'>Our orientation for conflict resolution is through compassion, in a supportive and safe environment.</span> <br />
                                When children feel supported, listened to and respected, they are more open to seeking solutions to conflicts and challenges that arise in their lives. <br />
                                We approach socialization challenges with a positive attitude, based on the premise that children respect and care for themselves and others. That is why we seek to create in our school spaces for reflection, dialogue, and nurturing community, which promote security, trust, cooperation, and closeness. Through careful observation, trial and error, and connection, we do our best to support each child as well as we can.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span className='color-programa'>Nuestra orientación para la resolución de conflictos es a través de la compasión, en un ambiente contenedor y seguro. </span><br />
                                Cuando los niños se sienten contenidos, escuchados y respetados, son más abiertos a buscar soluciones a conflictos y retos que se presentan en sus vidas. <br />
                                Abordamos los retos de socialización con una actitud positiva, basados en la premisa de que los niños se respetan y cuidan de sí mismos, y de los demás. Por eso buscamos crear en nuestra escuela espacios de reflexión, diálogo y sentido de comunidad, que promuevan seguridad, confianza, cooperación y cercanía. A través de la observación cuidadosa, prueba y error, y conexión, hacemos nuestro mejor esfuerzo para apoyar a cada niño lo mejor que podemos.<br />
                            </>)}
                        </div>
                    </div> 
                                 
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Guia;
