import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import "../experiencia.css";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Comunidad = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "School Community") || (!isInglish && "Comunidad")}>
                <div className='seccion'>
                   
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>School Community</>) || (!isInglish && <>Comunidad educativa</>)}</h2>
                            {(isInglish && 
                            <> 
                                At Little Creek we believe that community is a fundamental component of our practices, we seek to encourage children to build a sense of belonging and understanding that they are part of a local and global community. This connectivity and social interactions teach collaboration, diversity, and empathy.<br/>
                                <span className='color-experiencia'>We value the active participation of families in our educational community that allows us to ensure meaningful learning. It connects school and home, which are the most important places where our children develop, and this is how community learning happens. </span> <br />
                                We emphasize exchanges, collaboration, and dialogue, which nurture and enrich all the experiences that take place inside and outside of school.<br/>
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                En Little Creek creemos que la comunidad es un componente fundamental de nuestras prácticas, buscamos incentivar a los niños a construir un sentido de pertenencia y comprensión de que son parte de una comunidad local y global. Esta conectividad e interacciones sociales enseñan colaboración, diversidad y empatía.<br/>
                                <span className='color-experiencia'>Valoramos la participación de las familias en nuestra comunidad educativa que nos permite asegurar un aprendizaje significativo. </span> <br />
                                Conecta la escuela y el hogar, que son los lugares más importantes donde se desarrollan nuestros niños, y es así como se da el aprendizaje comunitario, donde hay intercambios, colaboración y diálogo, que nutren y enriquecen todas las experiencias que se dan dentro y fuera de la escuela.<br/>
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl ofcon' src="/images/community_seccion/little_creek_school_community_11.jpg" alt="community" />
                        </div>
                    </div>
                   
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/community_seccion/little_creek_school_community_2.jpg" alt="community" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                Our families and community members are encouraged to participate and share their skills in learning projects, spaces, and other areas you feel you can contribute to our school. Our doors are always open.<br/>
                                Practices that we promote in our school:<br/>
                                <li>Local excursions to get to know our community</li>
                                <li>Incursions with our local community members</li>
                                <li>Holding events in community</li>
                                <li>Incorporating your family’s culture, values, and languages</li>
                                <li>Embedding Indigenous culture into our program</li>
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Se alienta a nuestras familias y miembros de la comunidad a participar y compartir sus habilidades en proyectos de aprendizaje, espacios y otras áreas en las que sienta que puede contribuir a nuestra escuela. Nuestras puertas siempre están abiertas.<br/>
                                Prácticas que queremos fomentar en nuestra escuela:<br/>
                                <li>Excursiones locales para conocer nuestra comunidad</li>
                                <li>Invitaciones a miembros de nuestra comunidad local</li>
                                <li>Celebración de eventos para la comunidad</li>
                                <li>Incorporar la cultura, los valores y los idiomas de las familias</li>
                                <li>Integración de la cultura indígena en nuestro programa</li>
                            </>)}
                        </div>
                    </div>                
                  
                </div>
            </div>   
            <EndNavBar/>
        </>
    );
}

export default Comunidad;
