import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import "./calendario.css";
import EndNavBar from '../../../endNavBar/EndNavBar';
import ContactoAdmin from '../contactoAdmin/ContactoAdmin';

const Calendario = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>
            <div className='contSeccion contSeccionAdmin flex column' secciontitle={(isInglish && "Enrollment") || (!isInglish && "Administracion")} subsecciontitle={(isInglish && "Calendar") || (!isInglish && "Calendario")}>
                <div className='contTextoYContactoAdmin flex'>
                    <div className='textosAdmin flex column'>
                        {(isInglish && <h2 className="titulosExp tituloCalendario">School Calendar and Events</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Calendario Escolar y Eventos</h2>)}
                        {(isInglish &&
                        <>
                            Little Creek School is open year-round. We follow the Federal Holidays and the Coos Bay Public School calendar for fall, winter, and spring break. We take two weeks for summer break in August to prepare our school for the next school year.
                        </>)
                        ||
                        (!isInglish &&
                        <>
                            Little Creek School está abierto todo el año. Seguimos los días festivos federales y el calendario de las Escuelas Públicas de Coos Bay para las vacaciones de otoño, invierno y primavera. Tomamos dos semanas de vacaciones de verano en agosto para preparar nuestra escuela para el próximo año escolar.
                        </>)}

                        {(isInglish && <h2 className="titulosExp tituloCalendario">School Calendar and Events 2023 - 2024</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Nuestro Calendario Escolar y Eventos mayo - septiembre 2023</h2>)}
                        {(isInglish &&
                        <>
                            Little Creek School follows the Federal Holidays and the Coos Bay Public School calendar for fall, winter, and spring break. We will be closed all August 2024.<br /> 
                            <b>Our School Calendar</b> <br />
                            First Day of school: September 5th, 2023  <br />
                            Veterans Day: November 9th, 2023<br />
                            Thanksgiving Break: November 20th – 23rd, 2023<br />
                            Winter break: December 25th, 2023 - January 8th, 2024<br />
                            Martin Luther King: January 15th, 2024<br />
                            President’s Day: February 19th, 2024<br />
                            Spring break: March 25th – 28th, 2024 <br />
                            Memorial Day: May 27th, 2024 <br />
                            Juneteenth: June 19th, 2024 <br />
                            Independence Day: July 4th, 2024<br />
                            Enrollment for school year 2024 – 2025: July 22nd – 26th, 2024 <br />
                            Last day of school: July 31st, 2024<br />
                            Summer Break: August 2024<br />
                            Labor Day: September 2nd, 2024 <br />
                            First Day of school: September 3rd, 2024<br />
                        </>)
                        ||
                        (!isInglish &&
                        <>
                            Apertura de la escuela: lunes 1 de mayo de 2023<br />
                            Memorial Day: lunes 29 de mayo de 2023<br />
                            Juneteenth: lunes, 19 de junio de 2023<br />
                            Día de la Independencia: martes, 4 de julio de 2023<br />
                            Inscripciones para el año escolar 2023 – 2024: lunes 3 al 14 de julio de 2023<br />
                            Vacaciones de verano: 21 de agosto - 1 de septiembre de 2023<br />
                            Día del Trabajo: lunes, 4 de septiembre de 2023<br />
                            Primer día de clases: martes 05 de septiembre de 2023<br />
                            *Nuestro Calendario Escolar 2023 – 2024 se publicará la última semana de junio de 2023<br />

                        </>)}

                        {(isInglish && <h2 className="titulosExp tituloCalendario">Little Creek Family Events</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Eventos familiares de Little Creek: de mayo a agosto de 2023</h2>)}
                        {(isInglish &&
                        <>
                            Little Creek community - Welcome play and picnic (September 2023)<br />
                            Holidays and traditions - Winter Solstice Celebration (December 2023)<br />
                            Welcome Spring – Spring Hike with all Families (April 2024)<br />
                            Inti Raymi – Summer Solstice Celebration (June 2024)<br />
                        </>)
                        ||
                        (!isInglish &&
                        <>
                            Inti Raymi – Celebración de Verano: Domingo 25 de junio de 2023<br />
                            Agregaremos más eventos a medida que avancemos en el próximo año escolar.<br />
                        </>)}
                    </div>
                    <div className='contContactoAdmin flex'>
                        <ContactoAdmin/>     
                    </div>
                </div>
            </div>  
            <EndNavBar />
        </>
    );
}

export default Calendario;
