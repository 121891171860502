import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Enfoque = () => {
    const {isInglish} = useContext(IdiomaContext);
    
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Bilingual and Multicultural Learning") || (!isInglish && "Enfoque Multicultural Bilingüe")}>
                <div className='seccion'>
                    <h1 className='tituloExp'>{(isInglish && <>The Little Creek Experience</>) || (!isInglish && <>La experiencia Little Creek</>)}</h1>
                                       
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/bilingual_seccion/1_bilingual.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Bilingual and Multicultural Learning</>) || (!isInglish && <>Enfoque Multicultural Bilingüe</>)}</h2>
                            {(isInglish && 
                            <> 
                                Little Creek has a bilingual and multicultural immersion program where children are exposed to the Spanish language 60 - 100% of the day. <br />
                                <span className='color-experiencia'> We believe that language immersion promotes cultural intelligence and curiosity. <br /></span>
                                Bilingual and multicultural education promotes fundamental values of tolerance, respect, and empathy. All these through learning from diverse Hispanic and Latin American cultures that provide different perspectives on how we understand the world and are contrasted with the ideas of each family culture that make up our school. 
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Little Creek tiene un programa de inmersión bilingüe y multicultural donde los niños están expuestos al idioma español 60 - 100% del día. <br />
                                <span className='color-experiencia'>Creemos que la inmersión lingüística promueve la inteligencia cultural y la curiosidad.<br /></span>
                                La educación bilingüe y multicultural promueve valores fundamentales de tolerancia, respeto y empatía, por medio del aprendizaje de diversas culturas hispanas y latinoamericanas que brindan diferentes perspectivas de cómo entendemos el mundo, y se contrastan con las ideas propias de cada cultura familiar que integran nuestra escuela.<br />
                            </>)}
                        </div>
                    </div>
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Bilingual and Multicultural Learning</>) || (!isInglish && <>Enfoque Multicultural Bilingüe</>)}</h2>
                            {(isInglish && 
                            <>
                                <span className='color-experiencia'>The Spanish language is learned through experiences and learning projects that integrate its culture, music, literature, food, stories, traditions, festivities, customs, and dances.</span> <br />
                                Learning another language is not only based on grammar, but in a way that transcends into something more significant and profound and that allows children to integrate it in a more complex way.
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span className='color-experiencia'>Se aprende el idioma español a través de experiencias y proyectos de aprendizaje que integran su cultura, música, literatura, comida, historias, tradiciones, festividades, costumbres y bailes.</span> <br />
                                Aprender otro idioma no se basa solo en la gramática, sino que trasciende en algo más significativo y profundo y que permite a los niños integrarlo de una manera más compleja.
                            </>)}
                        </div>
                        <div className='contImgSecciones flex'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/2_bilingual.jpg" alt="bilingual" />
                        </div>
                    </div>                    
                                                           
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/little_creek_school_spanish_book_library.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h3 className='subTitulosExp'>{(isInglish && <>Spanish books library</>) || (!isInglish && <>Biblioteca de libros en español</>)}</h3>
                            {(isInglish && 
                            <>
                                At Little Creek we have a project underway to create a Spanish books library. These will be originally written in Spanish, by Hispanic and Latin American authors that show the culture and characteristics of our language.    
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                En Little Creek tenemos un proyecto en marcha de crear una biblioteca de libros en español escritos originalmente en español, de autores hispanos y latinoamericanos que muestran la cultura y las características propias de nuestro lenguaje.
                            </>)}
                        </div>
                    </div>   
                                  
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                        <h3 className='subTitulosExp'>{(isInglish && <>Cultural materials</>) || (!isInglish && <>Materiales culturales</>)}</h3>
                        {(isInglish && 
                            <>
                                We also have a project underway to collaborate and support Latin American artists and artisans. We want part of the materials and toys found in our school to come from different countries and show cultural diversity. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                También tenemos un proyecto en marcha de colaborar y apoyar a los artistas y artesanos latinoamericanos, queremos que parte de los materiales y juguetes que se encuentran en nuestra escuela vengan de diferentes países y muestran la diversidad cultural.<br />
                            </>)}    
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/5_otavalo.jpg" alt="bilingual" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/bilingual_seccion/little_creek_school_La_Guaytamba.jpg" alt="La Guaytamba" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                        {(isInglish && 
                            <>
                                <span> Our logo and illustrations of the Little Creek brand were designed by the artist <a href="https://www.instagram.com/la.guaytamba/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='linkAdmis' rel="noreferrer">La Guaytamba</a> (Quito-Ecuador).</span> <br />
                                <span> Our first toys and crafts that are already in our school come from the indigenous market of <a href="https://www.bbc.com/travel/article/20131230-otavalo-the-land-of-andean-artistry" target="_blank" className='linkAdmis' rel="noreferrer">Otavalo</a> in Ecuador. </span>
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span> Nuestro logo e ilustraciones de la marca Little Creek fueron diseñados por la artista <a href="https://www.instagram.com/la.guaytamba/?igshid=YmMyMTA2M2Y%3D" target="_blank" className='linkAdmis' rel="noreferrer">La Guaytamba</a> (Quito-Ecuador).</span> <br />
                                <span> Nuestros primeros juguetes y artesanías que ya están en nuestra escuela provienen del mercado indígena de <a href="https://www.bbc.com/travel/article/20131230-otavalo-the-land-of-andean-artistry" target="_blank" className='linkAdmis' rel="noreferrer">Otavalo</a> en Ecuador. </span>
                            </>)}    
                        </div>
                    </div>

                    <div className='contImgSolas flex wrap'>
                        <div className='contImgSola'>
                            <img className='imgSolas' src="/images/bilingual_seccion/3_otavalo.jpg" alt="bilingual" />
                        </div>
                        <div className='contImgSola'>
                            <img className='imgSolas' src="/images/bilingual_seccion/4_otavalo.jpg" alt="bilingual" />
                        </div>    
                    </div>    
                                                                                       
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Enfoque;
