import React from 'react';
import { useContext } from 'react';
import "./home.css";
import AutoCarousel from '../autoCarousel/AutoCarousel';
import Carousel from '../carousel/Carousel';
import { HashLink } from 'react-router-hash-link';
import { IdiomaContext } from "../../context/IdiomaContext";
import EndNavBar from '../endNavBar/EndNavBar';

const Home = () => {
    const {isInglish} = useContext(IdiomaContext);
       
    return ( 
        <div className='contSeccion seccionHome' secciontitle="Home">
            {/* Encabezado */}
            <div className='contHome OoS' id="home">
                <AutoCarousel/>   
                <div className='contColorHome flex'> 
                    {/* <h2 className='tituloHome bienVenidos'>{(isInglish && "Welcome to") || (!isInglish  && "Bienvenidos a")}</h2> */}
                    <img className='logoHome2' src="/images/logo4.png" alt="Logo Little Creek" />
                    {/* <h3 className='bilingual'>Bilingual School</h3> */}
                    <div className='homeFilter'></div>
                    {/* <img className='animales pajaro OoS' src="/images/pajaro.png" alt="pajaro" /> */}
                </div>
            </div>

            {/* Texto Principal */}
            <div className='flex column'>
                <div className='contTextoPcipalHome flex column'>
                    <div className='contTextoPcipalHome2 OoS'>
                        {(isInglish &&
                            <>  We are a registered home based school inspired by the <a href="https://www.reggiochildren.it/en/reggio-emilia-approach/valori-en/" target="_blank" className='linkAdmis' rel="noreferrer">Reggio Emilia philosophy</a>, serving children 2 to 8 years. We are located in rural Coos Bay in the middle of a beautiful forest and among creeks, hence our inspiration to name our school Little Creek.<br /><br />
                                Little Creek Bilingual School was born from our family's dream of finding a school where our son is valued for who he is and his potential to learn. A school where he can explore, play, create, investigate, in his own time and through his own languages. We want to share this space with families who have this same vision of education for their children.<br /><br />
                                <span className='titulosTextoPcipal'>Vision</span><br /><br />
                                Our vision is to create a different school in our community, a school that promotes bilingual and multicultural learning, a nature-based approach that recognizes traditional ecological knowledge, and all of this is delivered in an emergent project-based curriculum through creative languages.<br /><br />
                                <span className='titulosTextoPcipal'>Mission</span><br /><br />
                                Our mission is to create awareness in a community that considers the child as a complete and competent being, the protagonist of their own learning. A place where education is inspired by family cultures and their diversities, seeking to generate positive change in our community and in the world.<br /><br />
                            </>)
                            ||
                            (!isInglish &&
                            <>  Somos una escuela registrada inspirada en la <a href="https://www.reggiochildren.it/en/reggio-emilia-approach/valori-en/" target="_blank" className='linkAdmis' rel="noreferrer">Filosofía Reggio Emilia</a>, que atiende niños de 2 a 8 años. Estamos ubicados en la zona rural de Coos Bay, en medio de un hermoso bosque y riachuelos, de ahí surge nuestra inspiración de llamarnos Little Creek.<br /><br />
                                Little Creek Bilingual School nace de un sueño de nuestra familia de encontrar una escuela donde nuestro hijo sea valorado por lo que es y su potencial para aprender, una escuela donde pueda explorar, jugar, crear, investigar, en su propio tiempo y a través de sus propios lenguajes. Queremos compartir este espacio con las familias que tengan esta misma visión de la educación con sus hijos.<br /><br />
                                <span className='titulosTextoPcipal'>Visión</span><br /><br />
                                Nuestra visión es crear una escuela diferente en nuestra comunidad, una escuela que promueva el aprendizaje bilingüe y multicultural, un enfoque basado en la naturaleza que reconozca los conocimientos ecológicos ancestrales, y todo esto se da en un currículo emergente basado en proyectos a través de lenguajes creativos.<br /><br />
                                <span className='titulosTextoPcipal'>Misión</span><br /><br />
                                Nuestra misión es crear conciencia en nuestra comunidad que vea al niño como un ser íntegro y competente, protagonista de su propio aprendizaje, donde la educación esté inspirada en las culturas familiares y sus diversidades, buscando generar un cambio positivo en nuestra comunidad y en el mundo.<br /><br />
                            </>)
                        }
                    </div>
                </div>
            </div>
            <div className='cont-title-line-home flex OoS'><div className='title-line-home'></div></div>


            {/* 3 Secciones Experiencia */}
            <div className='contSeccionesHome flex'>
                <div className='contCardsExperiencia flex'>
                    <div className='cardExperiencia OoS'>
                        <div className='contTituloCards flex'><h2 className='tituloCards'>{(isInglish && "Bilingual and Multicultural Learning") || (!isInglish  && "Enfoque Multicultural Bilingüe")}</h2></div>
                        <div className='contImgExperiencia'>
                            <img className='imgExperiencia' src="/images/bilingual/b1.jpg" alt="" />
                        </div>
                        <div className='contTextoExperiencia flex column'>
                            <p className='textoCards'>
                                {(isInglish  && 
                                <>
                                    We have a bilingual and multicultural immersion program where children are exposed to the Spanish language 60 – 100% of the day. We believe that language immersion promotes cultural intelligence and curiosity.
                                </>)
                                || 
                                (!isInglish && 
                                <>
                                    Tenemos un programa de inmersión bilingüe y multicultural donde los niños están expuestos al idioma español 60 – 100% del día. Creemos que la inmersión lingüística promueve la inteligencia cultural y la curiosidad.
                                </>)}
                            </p>
                            <HashLink to="/experiencia/enfoque#top" className="contBotonCard flex"><button className='botonExperiencia'>{(isInglish && "More info") || (!isInglish  && "Seguir leyendo")}</button></HashLink>
                        </div>
                    </div>

                    <div className='cardExperiencia OoS'>
                        <div className='contTituloCards flex'><h2 className='tituloCards'>{(isInglish && "Nature Based Approach") || (!isInglish  && "Aprendizaje en la naturaleza")}</h2></div>
                        <div className='contImgExperiencia'>
                            <img className='imgExperiencia' src="/images/nature/n1.jpg" alt="" />
                        </div>
                        <div className='contTextoExperiencia flex column'>
                            <p className='textoCards'>
                                {(isInglish  && 
                                <>
                                    Our school is located in the middle of a beautiful forest and creeks, this allows us to provide quality time for outdoor learning, to observe, to explore, and have direct contact with nature.
                                </>)
                                || 
                                (!isInglish &&
                                <>
                                    Nuestra escuela está ubicada en medio de un hermoso bosque y riachuelos, esto nos permite brindar tiempo de calidad para el aprendizaje al aire libre, para observar, explorar y tener contacto directo con la naturaleza.
                                </>
                                )}
                            </p>
                            <HashLink to="/experiencia/nature#top" className="contBotonCard flex"><button className='botonExperiencia'>{(isInglish && "More info") || (!isInglish  && "Seguir leyendo")}</button></HashLink>
                        </div>
                    </div>

                    <div className='cardExperiencia OoS'>
                        <div className='contTituloCards flex'><h2 className='tituloCards'>{(isInglish && "The Atelier") || (!isInglish  && "Cultura del Atelier")}</h2></div>
                        <div className='contImgExperiencia'>
                            <img className='imgExperiencia program' src="/images/welcome/w1.jpg" alt="" />
                        </div>
                        <div className='contTextoExperiencia flex column'>
                            <p className='textoCards'>
                                {(!isInglish && 
                                <>
                                    Nuestra escuela está pensada como un gran Atelier en donde los niños tienen espacios donde pueden imaginar, experimentar, crear y expresarse por medio de diferentes medios, materiales y lenguajes artísticos. Se fomentan experiencias de música, danza, artes visuales y teatro.
                                </>)
                                || 
                                (isInglish  && 
                                <>
                                    Our school is seen as a big Atelier where children have spaces where they can imagine, experiment, create and express themselves through different media, materials, and artistic languages. Music, dance, visual arts, and theater experiences are encouraged.
                                </>
                                )}
                            </p>
                            <HashLink to="/experiencia/atelier#top" className="contBotonCard flex"><button className='botonExperiencia'>{(isInglish && "More info") || (!isInglish  && "Seguir leyendo")}</button></HashLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cont-title-line-home flex OoS'><div className='title-line-home'></div></div>
                      

            {/* Nuestro Equipo */}
            <div className='contSeccionesHome flex'>
                <h2 className='tituloHome OoS'>{(isInglish && "Our team") || (!isInglish  && "Equipo Little Creek")}</h2>
                <h3 className='subTituloHome'>{(isInglish && "Founder/Lead Teacher") || (!isInglish && "Directora")}</h3>
                <div className='contEquipo flex wrap'>
                    <div className='contImgsEquipo flex'>
                        <div className='contPerfil flex column OoS'>
                            <div className='contImgEquipo' id="team">
                                <a href="/images/team_seccion/little_creek_school_team_1.jpg" target='_blank' rel="noreferrer"><img className='imgEquipo' src="/images/team_seccion/little_creek_school_team_1.jpg" alt="team" /></a>
                            </div>
                        </div>
                    </div>
                    <div className='textoTeam flex column OoS'>
                        {isInglish &&
                            <p> I was born and raised in the beautiful city of Quito (Ecuador) surrounded by mountains and volcanoes. Thanks to my experiences living and teaching in a diversity of countries such as Ecuador, Argentina, Poland and now the United States, I have learned to be a flexible, creative, and an adventurous person.<br /><br />
                                <HashLink to="/team#top"><button className='botonExperiencia'>More Info</button></HashLink>
                            </p>}
                        {!isInglish &&
                            <p> Nací y crecí en la hermosa ciudad de Quito (Ecuador) rodeada de montañas y volcanes. Gracias a mis experiencias viviendo y enseñando en una diversidad de países como Ecuador, Argentina, Polonia y ahora los Estados Unidos, he aprendido a ser una persona flexible, creativa y aventurera.<br /><br />
                                <HashLink to="/team#top"><button className='botonExperiencia'>Mas Información</button></HashLink>
                            </p>}
                    </div>
                </div>
            </div>
            <div className='cont-title-line-home flex OoS'><div className='title-line-home'></div></div>
         

            {/* Galeria */}
            <div className='contSeccionesHome flex' id="galeria">
                <HashLink to="/home#top"><h2 className='tituloHome OoS' id="galeria">{(isInglish && "Photo gallery") || (!isInglish  && "Galería de Fotos")}</h2></HashLink>
                <Carousel className="OoS"/>
                <HashLink to="/galeria#top" className="contBotonGallery flex"><button className='botonExperiencia'>{(isInglish && "Go to the Gallery") || (!isInglish  && "Ir a la Galería")}</button></HashLink>
            </div>   
            <EndNavBar />
        </div>
    );
}

export default Home;
