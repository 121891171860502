import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import "./nature.css";

const Nature = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return ( 
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Nature Based Approach") || (!isInglish && "Aprendizaje en la naturaleza")}>
                <div className='seccion'>
                                        
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opr ofcon' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_2.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Nature Based Approach</>) || (!isInglish && <>Aprendizaje en la naturaleza</>)}</h2>
                            {(isInglish && 
                            <> 
                                Our school is located in the middle of a beautiful forest and creeks, this allows us to provide quality time for outdoor learning, to observe, to explore, and have direct contact with nature.<br />
                                Nature learning occurs naturally when children and nature are brought together. By getting their hands on the ground, exploring plants and living organisms in the soil, observing the changes that occur in their environment at different times of the year, children deepen their understanding of the cycles and rhythms that make up this life. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                Nuestra escuela está ubicada en medio de un hermoso bosque y riachuelos, esto nos permite brindar tiempo de calidad para el aprendizaje al aire libre, para observar, explorar y tener contacto directo con la naturaleza.<br />
                                El aprendizaje de la naturaleza ocurre naturalmente cuando los niños y la naturaleza se unen. Al poner sus manos en la tierra, explorar plantas y organismos vivos en el suelo, observar los cambios que ocurren en su entorno en diferentes momentos del año, los niños profundizan su comprensión de los ciclos y ritmos que componen esta vida. <br />
                            </>)}
                        </div>
                    </div>
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <> 
                                <span className='color-experiencia'>They are creating a sense of belonging with their surroundings and developing a sense of care for the natural parts of our planet.</span><br />
                                Kids interact in nature through play, a nature play that is fun, open-ended, self-directed, and freely chosen.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span className='color-experiencia'>Están creando un sentido de lugar con su entorno y desarrollando un sentido de cuidado por las partes naturales de nuestro planeta.</span><br />
                                Los niños interactúan en la naturaleza a través del juego, un juego natural que es divertido, abierto, autodirigido y elegido libremente.<br />
                            </>)}
                            <img src="/images/rana.png" className='stickerNature' alt="frog" />
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_5.jpg" alt="bilingual" />
                        </div>
                    </div>
                    
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_6.jpg" alt="bilingual" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="subTitulosExp">{(isInglish && <>Learning from Indigenous Knowledges and Lifeways</>) || (!isInglish && <>Aprendiendo de los conocimientos y estilos de vida indígenas</>)}</h2>
                            {(isInglish && 
                            <> 
                                In our school we seek to incorporate the vision and knowledge acquired by indigenous and local peoples over hundreds or thousands of years through direct contact with the environment.  This knowledge reflects a respect and admiration for the relationships between plants, animals, natural phenomena, landscapes, and timing of events that are used for ways of life, including but not limited to hunting, fishing, trapping, agriculture, and forestry.<br />
                                <span className='color-experiencia'>We will mainly use storytelling as a pathway to transmit ethics, values, relationships and reconnect with traditions. </span> <br />
                                Storytelling is a communication tool across indigenous cultures, providing a means of transmitting teachings on traditional customs, spiritual belief systems, and ecological knowledge and wisdom.<br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                En nuestra escuela buscamos incorporar la visión y el conocimiento adquirido por los pueblos indígenas y locales durante cientos o miles de años a través del contacto directo con el medio ambiente. Este conocimiento refleja un respeto y admiración por las relaciones que existen entre las plantas, los animales, los fenómenos naturales, los paisajes y el momento de los eventos que se utilizan para la vida, incluidos, entre otros, la caza, la pesca, las trampas, la agricultura y la silvicultura.<br />
                                <span className='color-experiencia'>Utilizaremos principalmente la narración oral de cuentos y leyendas como vía para transmitir ética, valores, relaciones y reconectar con las tradiciones. </span> <br />
                                La narración de cuentos es una herramienta de comunicación entre las culturas indígenas, que proporciona un medio para transmitir enseñanzas sobre costumbres tradicionales, sistemas de creencias espirituales y conocimientos y sabiduría ecológicos.<br />
                            </>)}
                        </div>
                    </div>   
                    
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="subTitulosExp">{(isInglish && <>Planet Guardians</>) || (!isInglish && <>Guardianes del planeta</>)}</h2>
                            {(isInglish && 
                            <> 
                                <span className='color-experiencia'>We believe that natural learning is transcendental because it allows our children from a very young age to understand and respect nature, build ideas of conservation, biology, ecology, and sustainability. </span><br />
                                This is fundamental knowledge that our community and the world need to face very complex challenges such as climate change, ocean pollution and species extinction.<br />
                                To support children's understanding of sustainable living, at Little Creek we encourage: recycling, single-use plastic reduction, water conservation, composting, use of biodegradable cleaning products when possible, use of natural light to reduce energy consumption, use of organic products in most of our foods and seasonal gardening. <br />
                            </>) 
                            || 
                            (!isInglish && 
                            <>
                                <span className='color-experiencia'>Creemos que el aprendizaje natural es trascendental porque permite que nuestros niños desde muy pequeños comprendan y respeten la naturaleza, construyan ideas de conservación, biología y ecología, y sustentabilidad. </span><br />
                                Estos son saberes necesarios que nuestra comunidad y el mundo necesitan para enfrentar desafíos muy complejos como el cambio climático, contaminación de los océanos y extinción de especies. <br />
                                Para apoyar la comprensión de sus hijos sobre la vida sostenible, en Little Creek alentamos: reciclaje, reducción de plástico de un solo uso, conservación del agua, compostaje, uso de productos de limpieza biodegradables cuando sea posible, uso de la luz natural para reducir el consumo de energía, uso de productos orgánicos en la mayoría de nuestros alimentos, y  sembrar, cuidar y consumir alimentos de nuestra huerta en temporada. <br />
                            </>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/nature_seccion/little_creek_school_Nature_Based Approach_3.jpg" alt="bilingual" />
                        </div>
                    </div>               
                                                
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Nature;
